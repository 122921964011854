import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import showToast from "@/components/toast/Toast";
import { Icon } from "@/components/ui";

import useLadyService from "@/services/LadyService";
import { InputInLabel } from "../../../../ui";

const InputZone = ({ setVideoPresentationValue, videoPresentationValue, data, setData, accept, title, subtitle, isVideo, isMulti }) => {
  const { t } = useLadyService();

  const getValidFormatsMessage = () => {
    return isVideo
      ? `${t("allowed")}: MP4, MOV, WMV, AVI, MKV`
      : `${t("allowed")}: JPG, JPEG, PNG, WEBP`;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      let newFiles = [];
      const filesToProcess = isMulti ? acceptedFiles : acceptedFiles.slice(0, 1);

      filesToProcess.forEach(async (file) => {
        if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\.\w+$/.test(file.name)) {
          return;
        }
        
        const isFileValid =
          isVideo ? file.type.startsWith("video/") : file.type.startsWith("image/");
        
        if (!isFileValid) {
          showToast({
            message: `${getValidFormatsMessage()}`,
            variant: "error",
          });
          return;
        }

        if (file.size > 20 * 1024 * 1024) {
          showToast({
            message: `${t("maxfilesize")}: 20 Mb`,
            variant: "error",
          });
          return;
        }

        const filePreview = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });

        newFiles.push(filePreview);
      });

      if (newFiles.length > 0) {
        setData((prevData) => {
          if (!isMulti) {
            return newFiles;
          }

          const newImages = newFiles.filter((file) => file.type.startsWith("image/"));
          const newVideos = newFiles.filter((file) => file.type.startsWith("video/"));
          return [...prevData, ...newImages, ...newVideos];
        });
      }
    },
    [setData, isMulti, isVideo, data, showToast]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
  });

  return (
    <div {...getRootProps({ className: "dropzone upload__zone" })}>
      <input {...getInputProps()} multiple={isMulti} />
      <Icon clazz={"_green mb-12"} size={"xl"} spritePath={"plus-1"} />

      {!!title && <span className="p1 color-main text-center w-50">{title}</span>}
      {isVideo && <span className="p1 color-main text-center">{t('orvideolink')}</span>}
      {!!subtitle && <span className="mb-12">{subtitle}</span>}
      {isVideo && (
        <InputInLabel
          type={"text"}
          id={"upload-agency-video"}
          clazz={"mt-12 align-start"}
          value={videoPresentationValue}
          placeholder={"https://vimeo.com/93721124"}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            setVideoPresentationValue(e.target.value);
          }}
        >
          {t("linktovideo")}
        </InputInLabel>
      )}
    </div>
  );
};



export default InputZone;
