import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { number, object, string } from "yup";
import useLadyService from "@/services/LadyService";
import { makeRequest } from "@/services/makeRequest";
import {
  getOneProfileAddress,
  getOneProfileCoords,
  setOneProfileAddress,
  setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice";
import { setUserInfoAlreadyExist } from "@/stores/slices/userSlice";
import {
  Button,
  Checkbox,
  ErrorMessage,
  Icon,
  Input,
  InputInLabel,
  Loader,
  LoadingButton,
  Select,
  SelectCountry,
} from "../../../../../../ui";
import debounce from "lodash.debounce";
import showToast from "../../../../../../toast/Toast";
import Map from "../../map/Map";
import GeoInput from "../../../../../../ui/input/GeoInput";
import "./main.scss";
import { Popup } from "../../../../../../popups";
import { removeSubstrings } from "@/helper/removeSubstrings";
import moment from "moment";

const MainPart = ({
  setOpen,
  register,
  errors,
  createProfileSlug,
  checkNickIsAvailable,
  nickValue,
  setAgeValue,
  formData,
  phoneValue,
  setPhoneValues,
  isLoading,
  setError,
  waValue,
  setWaValues,
  setNationality,
  transformedCountries,
  sortByName,
  nationality,
  setEthnos,
  options,
  setReadyTravel,
  handleLangOpen,
  langPopup,
  langsError,
  selectRef,
  searchValue,
  setSearchValue,
  data,
  langsCode,
  toggleLang,
  languages,
  sortedLanguages,
  langs,
  removeLang,
  citiesOptions,
  cityError,
  formCity,
  selectedCity,
  setSelectedCity,
  currentActive,
  setActiveCountry,
  activeCountry,
  setCurrentActive,
  throwError,
  address,
  geoError,
  bounds,
  handleLocationClick,
  isAddressActive,
  setBounds,
  mapMarker,
  setIsAddressActive,
  clearErrors,
  setCityError,
  setTg,
  setName,
  name,
  tg,
  setAge,
  changeCityDisable,
  isModal = false,
}) => {
  const { t, windowWidth } = useLadyService();
  const originalDate = moment.unix(formData.is_new_at).utc();

  const dateAfter7Days = originalDate.add(7, 'days');
  
  const formattedDate = dateAfter7Days.format("DD.MM.YYYY");

  return (
    <div className={`create-page__form main`}>
      <InputInLabel
        type={"text"}
        id={"create-name"}
        placeholder={""}
        value={name}
        register={{ ...register("name") }}
        clazz={`_required ${errors?.name ? "_error" : ""}`}
        onChange={(e) => {
          if (errors?.name) {
            clearErrors("name");
          }
          setName(e.target.value);
          createProfileSlug(e);
        }}
      >
        {t("nameofad")}
        {errors?.name && <ErrorMessage message={t("required")} />}
      </InputInLabel>

      <InputInLabel
        type={"text"}
        id={"create-nick"}
        placeholder={"https://lady4love.com/Vash-Nic"}
        register={{ ...register("nick") }}
        clazz={`_required ${errors.nick ? "_error" : ""}`}
        onChange={(e) => checkNickIsAvailable(e.target.value)}
        value={nickValue}
      >
        {t("nick")}
        {errors.nick && <ErrorMessage message={errors.nick.message} />}
      </InputInLabel>

      <InputInLabel
        type={"number"}
        id={"create-age"}
        placeholder={"18-99"}
        register={{ ...register("age") }}
        clazz={`_required ${errors.age ? "_error" : ""}`}
        onChange={(e) => {
          setAge(Number(e.target.value));
          setAgeValue(e);
        }}
        value={formData.age}
      >
        {t("age")}
        {errors.age && <ErrorMessage message={errors.age.message} />}
      </InputInLabel>

      <InputInLabel
        type={"tel"}
        id={"create-phone"}
        placeholder={"+XXX XXX XXX XXX"}
        register={{ ...register("phone") }}
        autocomplete={"tel"}
        clazz={`_required ${errors.phone?.message ? "_error" : ""}`}
        maskValue={phoneValue}
        setMaskValue={setPhoneValues}
        valueLoad={isLoading}
        setError={setError}
        errorType={"phone"}
        clearErrors={clearErrors}
        isModal={isModal}
      >
        {t("phonenumber")}
        {errors.phone && <ErrorMessage message={errors.phone.message} />}
      </InputInLabel>

      <InputInLabel
        type={"tel"}
        id={"create-whatsapp"}
        placeholder={""}
        clazz={`${errors.whatsapp?.message ? "_error" : ""}`}
        register={{ ...register("whatsapp") }}
        autocomplete={"whatsapp"}
        maskValue={waValue}
        setMaskValue={setWaValues}
        setError={setError}
        errorType={"whatsapp"}
        clearErrors={clearErrors}
      >
        {"Whatsapp"}
        {errors.whatsapp && <ErrorMessage message={errors.whatsapp.message} />}
      </InputInLabel>

      <InputInLabel
        type={"text"}
        id={"create-telegram"}
        placeholder={""}
        register={{ ...register("telegram") }}
        autocomplete={"telegram"}
        value={formData?.telegram || tg}
        onChange={(e) => setTg(e.target.value)}
      >
        {t("telegram")}
      </InputInLabel>

      <Select
        onChange={setNationality}
        name={"create-nationalist"}
        options={
          transformedCountries?.length > 0 && sortByName(transformedCountries)
        }
        clazz={"size-l-forever"}
        clazzSvg={"select__flag"}
        arrowSize={"m"}
        isSearch={true}
        searchPlaceHolder={t("searchfield")}
        type={"flag"}
        placeholder={t("nationality")}
        defaultTitle={nationality}
        withoutTitle={true}
        postApi={true}
        hideFlag={nationality ? false : true}
        withoutIcon={nationality ? false : true}
      />

      <Select
        clazz={"size-l-forever"}
        onChange={setEthnos}
        name={"create-ethnos"}
        options={options.ethnos}
        arrowSize={"m"}
        placeholder={t("ethnicity")}
        defaultTitle={
          formData.skin_color
            ? options.ethnos.filter(
                (item) => item.value === formData.skin_color
              )[0]?.title
            : ""
        }
        withoutTitle={true}
        postApi={true}
      />

      <Select
        clazz={"size-l-forever"}
        onChange={setReadyTravel}
        name={"create-readyTravel"}
        options={options.readyTravel}
        arrowSize={"m"}
        placeholder={t("travel")}
        defaultTitle={
          formData.ready_travel
            ? options.readyTravel.filter(
                (item) => item.value === formData.ready_travel
              )[0]?.title
            : ""
        }
        withoutTitle={true}
        postApi={true}
      />

      <div className={`select _required${langPopup ? " select_active" : ""}`}>
        <button
          type={"button"}
          onClick={() => handleLangOpen()}
          className={`select__button size-l-forever`}
        >
          <span className={"select__placeholder"}>{t("languages")}</span>
          <Icon
            clazz={"select__arrow"}
            size={"m"}
            spritePath={"chevrone-down"}
          />
          {langsError && <ErrorMessage message={langsError} />}
        </button>

        {langPopup && (
          <div
            ref={selectRef}
            className={`select__body gap-8`}
            id={"select-lang"}
          >
            <label htmlFor="citiesSearch" className="select__search">
              <Input
                type="search"
                placeholder={t("searchfield")}
                id="citiesSearch"
                clazz={"search__input"}
                name={"create-cities"}
                value={searchValue}
                setValue={setSearchValue}
              />
            </label>

            <div className="d-flex justify-sb gap-8">
              {data.standLang.map((i) => {
                return (
                  <Checkbox
                    border={false}
                    flag={i.short_name.toUpperCase()}
                    key={i.short_name.toUpperCase()}
                    name={i?.name}
                    id={i.short_name.toUpperCase()}
                    value={i?.name}
                    title={i.short_name.toUpperCase()}
                    checked={langsCode.includes(i.short_name)}
                    onChange={() =>
                      toggleLang({
                        id: i.id,
                        short_name: i.short_name,
                        name: i?.name,
                      })
                    }
                  />
                );
              })}
            </div>

            <div className="dropdown__search-select gap-8">
              {languages?.filter((item) =>
                item?.name.toLowerCase().includes(searchValue.toLowerCase())
              ).length > 0 ? (
                sortedLanguages
                  .filter((item) =>
                    item?.name.toLowerCase().includes(searchValue.toLowerCase())
                  )
                  .map((item) => (
                    <Checkbox
                      border={false}
                      flag={item.short_name.toUpperCase()}
                      key={item.short_name.toUpperCase()}
                      name={item?.name}
                      id={item.id}
                      value={item.short_name}
                      title={item?.name}
                      checked={
                        langsCode.includes(item.short_name) ? true : false
                      }
                      onChange={() => toggleLang(item)}
                    />
                  ))
              ) : (
                <span className="p2">{t("nolanguages")}</span>
              )}
            </div>
          </div>
        )}
      </div>

      {!!langs?.length && (
        <div className="create-lang--current">
          {langs.map((lang) => {
            return (
              <div className="select__item" key={lang?.name}>
                {lang?.name}
                <i
                  className={`icon-flag icon-flag-m ${lang?.short_name?.toUpperCase()}`}
                ></i>
                <i
                  className={`icon icon-m icon--close`}
                  onClick={() => removeLang(lang?.short_name)}
                ></i>
              </div>
            );
          })}
        </div>
      )}

      <SelectCountry
        data={citiesOptions}
        name={"city-create"}
        clazzWrapper={"select"}
        clazz={`size-l-forever _required ${cityError ? "_error" : ""} ${
          changeCityDisable || !!formData.is_new_at ? " disabled _disabled" : ""
        } grid-col-1`}
        clazzSvg={`select__flag ${formCity.icon}`}
        arrowSize={"m"}
        type={"flag"}
        placeholder={t("yourcity")}
        defaultTitle={selectedCity || formCity?.name}
        withoutIcon={!selectedCity}
        setValue={setSelectedCity}
        currentActiveIndex={currentActive}
        setActiveCountry={setActiveCountry}
        activeCountry={activeCountry}
        setActive={setCurrentActive}
        error={cityError}
        setError={setCityError}
        setIsAddressActive={setIsAddressActive}
        errorMessage={<ErrorMessage message={cityError} />}
      />

      {windowWidth && windowWidth < 767 && (
        <span className="create-page__noted grid-col-1">
           {!formData.is_new_at && t("i-changecityper")}
          {formData.is_new_at && (
            <span>
              {t("changecityav")}{" "}
              {formattedDate}
            </span>
          )}
        </span>
      )}

      <div className={`search filter__map-input`}>
        <div
          className={`search filter__map-input ${
            selectedCity ? "" : "_disabled"
          }`}
          onClick={(e) => throwError(e)}
        >
          <span className={"filter__map-title"}>{t("youraddress")}</span>
          <label
            htmlFor="geosuggest__input-create"
            className="search__label header__search"
          >
            <GeoInput
              value={address[0]}
              name={"location-create"}
              id="geosuggest__input-create"
              clazz={`header__search-input ${geoError ? "_error" : ""}`}
              placeholder={t("fulladdress")}
              bounds={bounds}
            />

            {geoError && <ErrorMessage message={t("cityfirst")} />}
          </label>
        </div>
        <Button
          title={t("chekmyplace")}
          onClick={handleLocationClick}
          name={"location"}
          square={true}
          buttonType={"button"}
          size={"l"}
          clazz={
            isAddressActive
              ? "search__icon button--tetriary active"
              : "search__icon button--tetriary"
          }
        >
          <Icon size={"xl"} spritePath={"location"} />
        </Button>
      </div>

      {windowWidth > 767 && (
        <span className="create-page__noted grid-col-1">
          {!formData.is_new_at && t("i-changecityper")}
          {formData.is_new_at && (
            <span>
              {t("changecityav")}{" "}
              {formattedDate}
            </span>
          )}
        </span>
      )}
      <span className="create-page__noted grid-col-2">
        {t("displayonsite")}
        <span className="create-page__noted-colored">
          {" "}
          +<span>35</span>% {t("toviews")}, +<span>15</span>% {t("toorders")}
        </span>
      </span>
      {selectedCity && (
        <Map
          setMarkerActive={setIsAddressActive}
          markerActive={isAddressActive}
          region={activeCountry}
          city={selectedCity}
          setBounds={setBounds}
          coords={mapMarker}
        />
      )}
    </div>
  );
};

const PopupButtons = ({ setOpen, isGlobalLoading, validateData }) => {
  const { t } = useLadyService();
  return (
    <div className="create-form__buttons">
      <Button
        size={"l-forever"}
        square={false}
        clazz="button--secondary min-w-160"
        onClick={() => setOpen(false)}
      >
        {t("cancel")}
      </Button>
      <LoadingButton
        isLoading={isGlobalLoading}
        onClick={validateData}
        buttonType={"submit"}
        size={"l-forever"}
        square={false}
        clazz="button--green min-w-160"
      >
        {t("save")}
      </LoadingButton>
    </div>
  );
};

const DefaultButtons = ({ validateData, isGlobalLoading, formData }) => {
  const { t } = useLadyService();
  return (
    <div className="create-form__buttons justify-end">
      <LoadingButton
        onClick={validateData}
        buttonType={"submit"}
        size={"l-forever"}
        square={false}
        isLoading={isGlobalLoading}
        clazz="button--green min-w-160"
      >
        {!formData?.slug ? t("create") : t("continue")}
      </LoadingButton>
    </div>
  );
};

const MainGlobal = ({
  changeStep,
  formData,
  setFormData,
  setAvailableSteps,
  cities,
  setData,
  nationalities,
  languages,
  setIsProfileAlreadyCreate,
  isProfileAlreadyCreate = true,
  setFilled,
  rootElem,
  open,
  setOpen,
  changeCityDisable,
}) => {
  const { lang, t, token, dispatch, city } = useLadyService();

  const data = {
    standLang: [
      {
        id: "1",
        short_name: "ru",
        name: !!languages?.length
          ? languages.filter((lang) => lang.short_name === "ru")[0]?.name
          : "",
      },
      {
        id: "3",
        short_name: "cz",
        name: !!languages?.length
          ? languages.filter((lang) => lang.short_name === "cz")[0]?.name
          : "",
      },
      {
        id: "2",
        short_name: "en",
        name: !!languages?.length
          ? languages.filter((lang) => lang.short_name === "en")[0]?.name
          : "",
      },
    ],
  };

  const options = {
    readyTravel: [
      { id: 93, title: t("nearcity"), value: "nearcity" },
      { id: 33, title: t("incountry"), value: "incountry" },
      { id: 32, title: t("ineurope"), value: "ineurope" },
      { id: 31, title: t("inworld"), value: "inworld" },
    ],
    ethnos: [
      { id: 99, title: t("white"), value: "white" },
      { id: 98, title: t("asian"), value: "asian" },
      { id: 97, title: t("african"), value: "african" },
      { id: 96, title: t("arabic"), value: "arabic" },
      { id: 95, title: t("indian"), value: "indian" },
      { id: 94, title: t("tanned"), value: "tanned" },
      { id: 93, title: t("mixed"), value: "mixed" },
      { id: 93, title: t("latin"), value: "latin" },
    ],
  };

  const currentUrl = window.location.href;
  const urlSlug = currentUrl.split("/create/")[1];

  const [isLoading, setIsLoading] = useState(
    isProfileAlreadyCreate ? false : true
  );

  const isFormDataLength =
    !!Object.values(formData).length && isProfileAlreadyCreate;

  const [isGlobalLoading, setIsGlobalLoading] = useState(false);

  const [activeCountry, setActiveCountry] = useState("cz");
  const address = useSelector(getOneProfileAddress);
  const coords = useSelector(getOneProfileCoords);
  const [langs, setLangs] = useState([]);
  const [langsCode, setLangsCode] = useState([]);
  const [readyTravel, setReadyTravel] = useState("");

  const [formCity, setFormCity] = useState({
    id: "",
    title: "",
    icon: "",
  });

  const [currentActive, setCurrentActive] = useState("");

  const transformedCountries = useMemo(
    () =>
      nationalities?.map((country) => ({
        icon: country.code.toUpperCase(),
        name: country?.name,
        title: country?.name,
        id: country.id,
      })),
    [nationalities]
  );

  function reformatData(data) {
    const result = [];
    for (const item of data) {
      for (const [key, value] of Object.entries(item)) {
        result.push({
          id: parseInt(key),
          short_name: value?.code,
          name: value?.name,
        });
      }
    }
    return result;
  }

  function getCodes(data) {
    const codes = [];
    for (const item of data) {
      for (const value of Object.values(item)) {
        codes.push(value?.code);
      }
    }
    return codes;
  }

  function convertData(data) {
    const result = [];
    Object.keys(data).forEach((key) => {
      const res = {};
      res[key] = {
        name: data[key]?.name,
        code: key,
        cities: data[key].cities,
      };
      result.push(res);
    });
    return result;
  }

  const citiesOptions = useMemo(() => convertData(cities), [cities]);

  const [langsError, setLangsError] = useState("");
  const [cityError, setCityError] = useState("");

  const [phoneValue, setPhoneValues] = useState(
    formData?.phone ? formData.phone : ""
  );

  const [waValue, setWaValues] = useState(
    formData?.whatsapp ? formData.whatsapp : ""
  );

  const [tg, setTg] = useState(formData?.telegram ? formData.telegram : "");
  const [age, setAge] = useState(formData?.age ? formData.age : "");
  const [name, setName] = useState(formData?.name ? formData.name : "");
  const [nick, setNick] = useState(formData?.slug ? formData.slug : "");

  const [slugValue, setSlugValue] = useState(
    formData.slug ? formData.slug : ""
  );

  const formSchema = object().shape({
    name: string().required(),
    nick: string().required(t("required")),
    age: number()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      )
      .nullable()
      .required(t("required"))
      .min(18, `${t("minimum")} 18`)
      .max(99, `${t("maxval")} 99`)
      .nullable(true),
    tel: string().test("phone-is-empty", t("phonenumerrequired"), function () {
      return !!phoneValue;
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [langPopup, setLangPopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [isAddressActive, setIsAddressActive] = useState(false);

  const checkAge = useCallback(
    debounce((value) => {
      if (value >= 18 && value <= 99) {
        clearErrors("age");
      }
      if (!value) {
        clearErrors("age");
      }
      if (value < 18) {
        setError("age", {
          message: `${t("minimum") + " 18"}`,
        });
      }
      if (value > 99) {
        setError("age", {
          message: `${t("maxval") + " 99"}`,
        });
      }
    }, 1500),
    []
  );

  const setAgeValue = (e) => {
    const value = parseInt(e.target.value, 10);

    checkAge(value);
  };

  const [selectedCity, setSelectedCity] = useState(
    !!formData?.city && !!citiesOptions?.length
      ? citiesOptions
          .find((item) => activeCountry in item)
          [activeCountry].cities.filter((item) => {
            return item?.name === formData.city || item.id === formData.city;
          })[0]?.name
      : ""
  );

  const validateData = () => {
    setLangsError("");
    setCityError("");
    if (langs.length === 0) {
      setLangsError(t("required"));
    }
    if (!selectedCity) {
      setCityError(t("required"));
    }
  };

  const [geoError, setGeoError] = useState(false);

  const throwError = (e) => {
    if (
      !e.target.classList.contains("icon--location") &&
      !e.target.classList.contains("button--tetriary")
    ) {
      if (!selectedCity) {
        setGeoError(true);

        setTimeout(() => {
          setGeoError(false);
        }, 2000);
      }
    }
  };

  const sortByName = (arr) => {
    if (Array.isArray(arr)) {
      return [...arr].sort((a, b) => {
        if (a?.name < b?.name) {
          return -1;
        }
        if (a?.name > b?.name) {
          return 1;
        }
        return 0;
      });
    }
  };

  const sortedLanguages = sortByName(languages);

  const [ethnos, setEthnos] = useState("");
  const [nationality, setNationality] = useState(
    !!transformedCountries?.length
      ? transformedCountries.filter((i) => {
          return (
            i.id === formData.nationality || i?.name === formData.nationality
          );
        })[0]?.title
      : ""
  );

  const [nickValue, setNickValue] = useState(
    formData.slug ? `https://lady4love.com/${formData.slug}` : ""
  );

  const [mapMarker, setMapMarker] = useState({ lat: null, lng: null });

  useEffect(() => {
    if (!!citiesOptions?.length) {
      const filteredCity = citiesOptions
        .find((item) => activeCountry in item)
        [activeCountry].cities.filter(
          (city) => city?.name === formData.city || city.id === formData.city
        );
      if (filteredCity[0]) {
        setFormCity({ ...filteredCity[0], icon: activeCountry });
      }
    }
  }, []);

  useEffect(() => {
    if (!!languages?.length && !isFormDataLength) {
      const filteredLanguages = languages.filter((language) =>
        formData.languages?.includes(language.id)
      );
      setLangs(filteredLanguages);

      const filteredLangsCode = filteredLanguages.map(
        (lang) => lang.short_name
      );
      setLangsCode(filteredLangsCode);
    }
  }, [formData.languages, languages, isFormDataLength]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        if (data) {
          setData(data.data);
          const result = data.data;
          setPhoneValues(result.phone);
          setWaValues(String(result.whatsapp ?? ""));
          setTg(result.telegram);
          setPhoneValues(result.phone);
          dispatch(setUserInfoAlreadyExist());
          if (result.address) {
            dispatch(setOneProfileAddress(result.address));
          }
          if (result.city_id) {
            setSelectedCity(
              citiesOptions[0].cz.cities.filter(
                (item) => item.id === Number(result.city_id)
              )[0].name
            );
          }
        }
        setIsLoading(false);
      } catch (error) {}
    };

    !urlSlug &&
      token &&
      !isProfileAlreadyCreate &&
      citiesOptions.length &&
      fetchData();
  }, [token, urlSlug, citiesOptions]);

  useEffect(() => {
    if (isFormDataLength) {
      setFormCity({
        id: formData?.city_id,
        title: formData?.city,
        icon: "CZ",
        name: formData?.city,
      });

      setLangs(reformatData(formData.languages));

      setLangsCode(getCodes(formData.languages));

      setCurrentActive(formData.city_id);

      setNickValue(
        formData.slug ? `https://lady4love.com/${formData.slug}` : ""
      );

      setMapMarker({
        lat: formData.latitude ? formData.latitude : null,
        lng: formData.longitude ? formData.longitude : null,
      });
    }
  }, [isFormDataLength]);

  useEffect(() => {
    if (formData?.name) {
      setName(formData?.name);
      setNick(formData.slug);
      setAge(formData.age);
      setTg(formData.telegram);
      setReadyTravel(formData.ready_travel);
      setEthnos(formData.skin_color);
    }
  }, []);

  const handleLangOpen = () => {
    setLangPopup((prev) => !prev);
  };

  const [bounds, setBounds] = useState("");

  const removeLang = (code) => {
    const updateLangs = langs.filter((item) => item.short_name !== code);
    const updateLangsCode = langsCode.filter((item) => item !== code);

    setLangs(updateLangs);
    setLangsCode(updateLangsCode);
  };

  const toggleLang = (item) => {
    setLangsError("");
    const lang = {
      id: Number(item.id),
      short_name: item.short_name,
      name: item?.name,
    };
    const langIndex = langs.findIndex(
      (item) => item.short_name === lang.short_name
    );

    if (langIndex === -1) {
      setLangs([...langs, lang]);
      setLangsCode([...langsCode, item.short_name]);
    } else if (langIndex !== -1) {
      const updatedLangs = [...langs];
      updatedLangs.splice(langIndex, 1);
      setLangs(updatedLangs);
      const resultArr = [...langsCode];
      resultArr.splice(langIndex, 1);
      setLangsCode(resultArr);
    }
  };

  const handleLocationClick = () => {
    setIsAddressActive(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const { latitude, longitude } = position.coords;

        setMapMarker({ lat: latitude, lng: longitude });
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1&accept-language=${lang}`
          );
          const {
            city: sityRes,
            road,
            house_number,
            town,
          } = response.data.address;

          const city = sityRes || town;

          let uncorrectName = false;

          citiesOptions[0][activeCountry].cities.map((item) => {
            if (
              item.name.toLowerCase() !== city.toLowerCase() &&
              !uncorrectName
            ) {
              uncorrectName = true;
              console.log("check");
              setSelectedCity(
                citiesOptions[0][activeCountry].cities.filter(
                  (city) => city.slug === "prague"
                )[0].name
              );
              setCurrentActive(1);
              // setActiveCountry("cz");
              // setFormCity({
              //   id: "",
              //   title: "",
              //   icon: "",
              // });
              return null;
            }
          });
          setBounds({ lat: latitude, lng: longitude });

          const substringsToRemove = [
            "Столица",
            "Capital",
            "The Capital",
            "Hlavní město",
          ];

          if (citiesOptions?.length) {
            if (
              citiesOptions
                .find((item) => activeCountry in item)
                [activeCountry].cities.some((obj) => {
                  return (
                    obj?.name.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove)
                        .trim()
                        .toLowerCase() ||
                    obj.slug.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove)
                        .trim()
                        .toLowerCase()
                  );
                })
            ) {
              const currentCity = citiesOptions
                .find((item) => activeCountry in item)
                [activeCountry].cities.filter((i) => {
                  return (
                    i?.name.toLowerCase() ===
                      removeSubstrings(
                        city,
                        substringsToRemove
                      ).toLowerCase() ||
                    i.slug.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove).toLowerCase()
                  );
                })[0];

              if (currentCity.id) {
                setSelectedCity(currentCity?.name);
                setCurrentActive(currentCity.id);
                setActiveCountry("cz");
                setFormCity({
                  ...currentCity,
                  icon: "CZ",
                });
              }
            }
          }

          dispatch(
            setOneProfileAddress(
              `${road} ${house_number ? ", " + house_number : ""}`
            )
          );
          dispatch(setOneProfileCoords({ latitude, longitude }));
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target) &&
      !event.target.classList.contains("select_active") &&
      !event.target.classList.contains("select__subtitle") &&
      !event.target.classList.contains("select__button")
    ) {
      event.stopPropagation();
      setLangPopup(false);
    }
  };

  const selectRef = useRef(null);

  const createSlug = useCallback(
    debounce((value) => {
      const fetchData = async () => {
        try {
          const route = `user/profile/slug/${value}`;
          const method = "GET";

          const data = await makeRequest({ route, method });
          if (data) {
            if (data.status) {
              checkNickIsAvailable(value);
              setValue("nick", value);
              setSlugValue(value);
            }
            if (data.slug) {
              checkNickIsAvailable(data.slug);
              setValue("nick", data.slug);
              setSlugValue(data.slug);
            }
          }
        } catch (error) {}
      };

      token && fetchData();
    }, 1000),
    []
  );

  const createProfileSlug = (e) => {
    createSlug(e.target.value);
  };

  const checkNick = useCallback(
    debounce((value) => {
      const fetchData = async () => {
        try {
          const route = `user/profile/check-slug/${value}`;
          const method = "GET";

          const data = await makeRequest({ route, method });
          if (data) {
            const status = data.status;
            if (!status) {
              const alternative = data.alternative;
              setError("nick", {
                message: `${t("taken")} ${alternative}`,
              });
            } else {
              clearErrors("nick");
            }
          }
        } catch (error) {}
      };

      token && fetchData();
    }, 750),
    []
  );

  const checkNickIsAvailable = (value) => {
    let nick = value.substring(value.lastIndexOf("/") + 1);
    nick = nick.replace(/[^a-zA-Z0-9-]/g, "");
    if (nick === "lady4lovecom") {
      setNickValue("");
    } else {
      setNickValue(`https://lady4love.com/${nick}`);
    }
    checkNick(nick);
  };

  const onSubmit = (data) => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsGlobalLoading(true);
      try {
        const route = isProfileAlreadyCreate
          ? `user/profile/${formData.slug}`
          : `user/profile`;
        const method = isProfileAlreadyCreate ? "PUT" : "POST";
        const payload = {
          ...(method === "PUT" && { isFull: true }),
          ...(method === "PUT" && { lang }),
          ...(!!waValue.replace(/[+]/g, "") && {
            whatsapp: String(waValue.replace(/[" "+]/g, "")),
          }),
          ...(!!data.telegram && { telegram: data.telegram.replace(/^@/, "") }),
          name: data?.name,
          nick: data.nick.substring(data.nick.lastIndexOf("/") + 1),
          ...(method === "POST" && { slug: slugValue }),
          phone:
            typeof phoneValue === "number"
              ? phoneValue
              : Number(phoneValue?.replace(/[" "+]/g, "")),
          age: data.age,
          languages: langs.map((item) => item.id),
          city: citiesOptions
            .find((item) => activeCountry in item)
            [activeCountry].cities.filter(
              (item) => item?.name.toLowerCase() === selectedCity.toLowerCase()
            )[0].id,
          ...(!!address && address[0] && { address: address[0] }),
          ...(!!coords?.latitude && { latitude: coords?.latitude }),
          ...(!!coords?.longitude && { longitude: coords?.longitude }),
          ...(!!ethnos && { skin_color: ethnos }),
          ...(!!nationality && {
            nationality: transformedCountries.filter(
              (i) => i?.name === nationality
            )[0].id,
          }),
          ...(!!readyTravel && { ready_travel: readyTravel }),
        };

        const result = await makeRequest({ route, method, payload });
        if (result.status) {
          setIsLoading(false);
          setIsGlobalLoading(false);
          setFormData({
            ...result.profile,
          });
          !!setOpen && setOpen(false);
          !!setFilled && setFilled(result.profile.completion);

          if (!isProfileAlreadyCreate) {
            const currentPath = window.location.pathname;
            const newPath = `${currentPath}/${slugValue}`;
            window.history.pushState({}, null, newPath);
            setIsProfileAlreadyCreate(true);
          }

          !!changeStep && changeStep((prev) => prev + 1);

          !!setAvailableSteps && setAvailableSteps((prev) => [...prev, 2]);
          !!rootElem &&
            rootElem.scrollIntoView({
              behavior: "smooth",
            });
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
        setIsLoading(false);
        setIsGlobalLoading(false);
      }
    };
    if (langsError) {
      rootElem.scrollIntoView({
        behavior: "smooth",
      });
    }
    token && !langsError && fetchData();
  };

  useEffect(() => {
    if (isProfileAlreadyCreate && city) {
      setFormData((prev) => ({
        ...prev,
        ...(!!waValue.replace(/[+]/g, "") && {
          whatsapp: String(waValue.replace(/[" "+]/g, "")),
        }),
        ...(!!tg && { telegram: tg.replace(/^@/, "") }),
        name: name,
        nick: nick.substring(nick.lastIndexOf("/") + 1),
        phone:
          typeof phoneValue === "number"
            ? phoneValue
            : Number(phoneValue?.replace(/[" "+]/g, "")),
        age: age,
        languages: langs?.map((item) => ({
          [item.id]: {
            code: item.short_name,
            name: item.name,
          },
        })),
        city: citiesOptions
          .find((item) => activeCountry in item)
          [activeCountry].cities.filter(
            (item) => item?.name.toLowerCase() === selectedCity.toLowerCase()
          )[0]?.id,
        ...(!!address && address[0] && { address: address[0] }),
        ...(!!coords?.latitude && { latitude: coords?.latitude }),
        ...(!!coords?.longitude && { longitude: coords?.longitude }),
        ...(!!ethnos && { skin_color: ethnos }),
        ...(!!nationality && {
          nationality: transformedCountries.filter(
            (i) => i?.name === nationality
          )[0].id,
        }),
        ...(!!readyTravel && { ready_travel: readyTravel }),
      }));
    }
  }, [
    activeCountry,
    address,
    age,
    citiesOptions,
    coords?.latitude,
    coords?.longitude,
    ethnos,
    langs,
    name,
    nationality,
    nick,
    phoneValue,
    readyTravel,
    selectedCity,
    setFormData,
    tg,
    transformedCountries,
    waValue,
    city,
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  if (!!setOpen) {
    return (
      <>
        {isLoading && (
          <div className="loader__overlay">
            <Loader height={100} />
          </div>
        )}

        <Popup
          open={open}
          setOpen={handleClose}
          onSubmit={handleSubmit(onSubmit)}
          clazz={`popup-form__body__edit`}
        >
          <h2>{t("main")}</h2>
          <MainPart
            name={name}
            setOpen={setOpen}
            setIsAddressActive={setIsAddressActive}
            register={register}
            errors={errors}
            createProfileSlug={createProfileSlug}
            checkNickIsAvailable={checkNickIsAvailable}
            nickValue={nickValue}
            setAgeValue={setAgeValue}
            formData={formData}
            phoneValue={phoneValue}
            setPhoneValues={setPhoneValues}
            isLoading={isLoading}
            setError={setError}
            waValue={waValue}
            setWaValues={setWaValues}
            setNationality={setNationality}
            transformedCountries={transformedCountries}
            sortByName={sortByName}
            nationality={nationality}
            setEthnos={setEthnos}
            options={options}
            setReadyTravel={setReadyTravel}
            handleLangOpen={handleLangOpen}
            langPopup={langPopup}
            langsError={langsError}
            selectRef={selectRef}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            data={data}
            langsCode={langsCode}
            toggleLang={toggleLang}
            languages={languages}
            sortedLanguages={sortedLanguages}
            langs={langs}
            removeLang={removeLang}
            citiesOptions={citiesOptions}
            cityError={cityError}
            formCity={formCity}
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
            currentActive={currentActive}
            setActiveCountry={setActiveCountry}
            activeCountry={activeCountry}
            setCurrentActive={setCurrentActive}
            throwError={throwError}
            address={address}
            geoError={geoError}
            bounds={bounds}
            handleLocationClick={handleLocationClick}
            isAddressActive={isAddressActive}
            setBounds={setBounds}
            mapMarker={mapMarker}
            setTg={setTg}
            setName={setName}
            tg={tg}
            setAge={setAge}
            changeCityDisable={changeCityDisable}
            isModal={true}
          />
          <PopupButtons
            setOpen={setOpen}
            isGlobalLoading={isGlobalLoading}
            validateData={validateData}
          />
        </Popup>
      </>
    );
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <h2>{t("main")}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MainPart
          name={name}
          setCityError={setCityError}
          setOpen={setOpen}
          register={register}
          errors={errors}
          createProfileSlug={createProfileSlug}
          checkNickIsAvailable={checkNickIsAvailable}
          nickValue={nickValue}
          setAgeValue={setAgeValue}
          formData={formData}
          phoneValue={phoneValue}
          setPhoneValues={setPhoneValues}
          isLoading={isLoading}
          setError={setError}
          waValue={waValue}
          setWaValues={setWaValues}
          setNationality={setNationality}
          transformedCountries={transformedCountries}
          sortByName={sortByName}
          nationality={nationality}
          setEthnos={setEthnos}
          options={options}
          setReadyTravel={setReadyTravel}
          handleLangOpen={handleLangOpen}
          langPopup={langPopup}
          langsError={langsError}
          selectRef={selectRef}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          data={data}
          langsCode={langsCode}
          toggleLang={toggleLang}
          languages={languages}
          sortedLanguages={sortedLanguages}
          langs={langs}
          removeLang={removeLang}
          citiesOptions={citiesOptions}
          cityError={cityError}
          formCity={formCity}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          currentActive={currentActive}
          setActiveCountry={setActiveCountry}
          activeCountry={activeCountry}
          setCurrentActive={setCurrentActive}
          throwError={throwError}
          address={address}
          geoError={geoError}
          bounds={bounds}
          handleLocationClick={handleLocationClick}
          isAddressActive={isAddressActive}
          setBounds={setBounds}
          mapMarker={mapMarker}
          setIsAddressActive={setIsAddressActive}
          clearErrors={clearErrors}
          setTg={setTg}
          setName={setName}
          tg={tg}
          setAge={setAge}
          changeCityDisable={changeCityDisable}
        />
        <DefaultButtons
          validateData={validateData}
          isGlobalLoading={isGlobalLoading}
          formData={formData}
        />
      </form>
    </>
  );
};

export default MainGlobal;
