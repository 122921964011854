import { InputInLabel, Button } from "@/components/ui";
import { useState, useEffect } from "react";

import showToast from "../../../../../toast/Toast";

import { makeRequest } from "@/services/makeRequest";

import _ from "lodash";

import "./infoForm.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import useLadyService from "@/services/LadyService";
import { getOneProfileAddress } from "@/stores/slices/oneProfileSlice";
import { Map } from "../../../createProfile/pageComponent";
import { useSelector } from "react-redux";
import { AddressRow } from "../addressRow/AddressRow";
import { convertData } from "@/helper/convertData";

const EmailLabel = () => {
  const { t, emailVerifyAt } = useLadyService();

  return !emailVerifyAt ? (
    <span className="error">Email - {t("notconfirmed")}</span>
  ) : (
    <span>Email</span>
  );
};

const InfoForm = ({ data, cities = [], type }) => {
  const {
    email,
    telegram,
    phone,
    whatsapp,
    latitude,
    longitude,
    address: resAdress,
    city_id,
    city,
    name,
    address,
  } = data;

  // const address = useSelector(getOneProfileAddress);

  const { t } = useLadyService();

  const resData = {
    email,
    whatsapp,
    telegram,
    ...(type === "customer" && { name }),
    address: resAdress,
  };

  const [valueLoad, setValueLoad] = useState(false);

  const [whatsappData, setWhatsappData] = useState(whatsapp);

  const [isActive, setIsActive] = useState(false);
  const [isActiveAddress, setIsActiveAddress] = useState(false);

  const citiesOptions = convertData(cities);
  const [activeAddress, setActiveAddress] = useState(0);
  const [formCity, setFormCity] = useState({
    id: "",
    title: "",
    icon: "",
  });

  const [bounds, setBounds] = useState("");

  const [currentActive, setCurrentActive] = useState("");
  const [activeCountry, setActiveCountry] = useState("cz");

  const [mapMarker, setMapMarker] = useState({
    lat: latitude || null,
    lng: longitude || null,
  });

  const formSchema = object().shape({
    email: string().email().required(t("emailisnes")),
  });

  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const [forms, setForms] = useState([
    { id: Date.now(), city: "", address: "" },
  ]);

  const handleAddForm = () => {
    setForms([...forms, { id: Date.now(), city: "", address: "" }]);
  };

  const handleRemoveForm = (id) => {
    setForms(forms.filter((form) => form.id !== id));
  };

  const handleChange = (id, name, value) => {
    setForms(
      forms.map((form) => (form.id === id ? { ...form, [name]: value } : form))
    );
  };

  const handleChangeActive = (e) => {
    e.preventDefault();
    setIsActive(true);
  };

  useEffect(() => {
    if (city) {
      setForms(
        forms.map((item) =>
          true ? { ...item, address: address, city: city } : item
        )
      );
    }
  }, [city]);

  const onSubmit = (data, e) => {
    e.preventDefault();

    const { email: newEmail, telegram: newTelegram, name: newName } = data;

    const equalData = {
      email: newEmail,
      whatsapp: Number(String(whatsappData).replace(/[" "+]/g, "")),
      telegram: newTelegram,
      ...(type === "customer" && { name: newName }),
      // address: address[0] || resAdress,
      address: forms[activeAddress].address,
    };

    const isFilesChanged = !_.isEqual(resData, equalData);

    const fetchData = async () => {
      try {
        const route = `user`;
        const method = "PUT";
        const payload = {
          email: newEmail,
          whatsapp: String(whatsappData).replace(/[" "+]/g, ""),
          telegram: newTelegram,
          phone,
          ...(type === "customer" && { name: newName }),
          // address: address[0],
          latitude: bounds.lat,
          longitude: bounds.lng,
          city_id: currentActive,
          // address: forms[activeAddress].address,
        };

        setIsActive(false);
        showToast({
          message: t("success"),
          variant: "success",
        });

        await makeRequest({ route, method, payload });
      } catch (error) {
        const res = error.response;

        // eslint-disable-next-line default-case
        switch (res.status) {
          case 401: {
            showToast({
              message: t("noauth"),
              variant: "error",
            });
            break;
          }
          case 422: {
            showToast({
              message: t("oops"),
              variant: "error",
            });
            break;
          }
        }
      }
    };

    isFilesChanged ? fetchData() : setIsActive(false);
  };
  const [selectedCity, setSelectedCity] = useState(
    !!city && !!citiesOptions?.length
      ? citiesOptions
          .find((item) => activeCountry in item)
          [activeCountry].cities.filter((item) => {
            return item?.name === city || item.id === city;
          })[0]?.name
      : ""
  );
  useEffect(() => {
    setValueLoad(false);
    setFormCity({
      id: city_id,
      title: city,
      icon: "CZ",
      name: city,
    });
    setValue("name", name);
    setValue("email", email);
    setValue("telegram", telegram);
    setValue("whatsapp", whatsappData);
    setValueLoad(true);
  }, [email, setValue, telegram, whatsappData, name, city_id, city]);

  useEffect(() => {
    if (!!citiesOptions?.length) {
      const filteredCity = citiesOptions
        .find((item) => activeCountry in item)
        [activeCountry].cities.filter(
          (city) => city?.name === city || city.id === city
        );
      if (filteredCity[0]) {
        setFormCity({ ...filteredCity[0], icon: activeCountry });
      }
    }
  }, []);

  return (
    <>
      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={`info-form-${type}`}>
          {type === "customer" && (
            <InputInLabel
              type={"text"}
              id={"name_id"}
              placeholder={t("names")}
              disabled={!isActive}
              register={{ ...register("name") }}
            >
              {t("names")} ({t("nick")})
            </InputInLabel>
          )}
          <InputInLabel
            type={"email"}
            id={"email"}
            placeholder={"Email"}
            disabled={!isActive}
            register={{ ...register("email") }}
          >
            <EmailLabel />
          </InputInLabel>
          <InputInLabel
            type={"input"}
            id={"telegram"}
            placeholder={"Telegram"}
            disabled={!isActive}
            register={{ ...register("telegram") }}
          >
            Telegram
          </InputInLabel>

          <InputInLabel
            type={"tel"}
            id={"whatsappPhone"}
            placeholder={t("phonenumber")}
            disabled={!isActive}
            register={{ ...register("whatsapp") }}
            maskValue={whatsappData}
            setMaskValue={setWhatsappData}
            valueLoad={valueLoad}
          >
            {t("whatsapp")}
          </InputInLabel>
          
        </div>
        {!isActive && (
            <Button
              size={"s"}
              clazz={
                "button_outline--green-accent justify-center w-160 mb-12 ml-auto d-flex"
              }
              onClick={handleChangeActive}
            >
              {t("edit")}
            </Button>
          )}
          {isActive && (
            <Button
              size={"s"}
              buttonType={"submit"}
              clazz={
                "button_outline--green-accent justify-center w-160 mb-12 ml-auto d-flex"
              }
            >
              {t("save")}
            </Button>
          )}
        <AddressRow
          phone={phone}
          email={getValues("email")}
          mapMarker={mapMarker}
          setMapMarker={setMapMarker}
          onRemove={handleRemoveForm}
          onChange={handleChange}
          onAdd={handleAddForm}
          address={address}
          addresses={forms}
          setAddresses={setForms}
          setIsActive={setIsActiveAddress}
          resAdress={resAdress}
          setActiveAddress={setActiveAddress}
          activeAddress={activeAddress}
          activeCountry={activeCountry}
          setActiveCountry={setActiveCountry}
          setBounds={setBounds}
          bounds={bounds}
          currentActive={currentActive}
          setCurrentActive={setCurrentActive}
          setSelectedCity={setSelectedCity}
          selectedCity={selectedCity}
          setFormCity={setFormCity}
          formCity={formCity}
          cities={cities}
          isActive={isActiveAddress}
        />
      </form>
    </>
  );
};

export default InfoForm;
