import { Button, ButtonLink, Icon, RatingStars } from "@/components/ui";
import React, { useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Link } from "react-router-dom";
import useLadyService from "@/services/LadyService";

import { useTranslation } from "react-i18next";
import "./agencyCard.scss";

const AgencyCard = ({ clazz = "", buttonColor = "button--primary", props }) => {
  const {
    id,
    name,
    address: addressData,
    rating,
    phone,
    description,
    whatsapp,
    telegram,
    website,
    cover,
    image,
    profiles,
  } = props;

  const { address = null } = addressData || {};

  const { lang } = useLadyService();

  const [clicked, setClicked] = useState(false);

  const { t } = useTranslation("translation");

  const postAltAgency = t("altagency").replace(/%([^%]+)%/g, name);

  return (
    <div className={`agency-card ${clazz}`}>
      <Link
        to={`/agency/${id}`}
        title={postAltAgency}
        className="agency-card__img"
      >
        <img src={image ?? cover} alt={postAltAgency} />
      </Link>
      <div className="agency-card__content">
        <div className="agency-card__heading">
          <Link to={`/agency/${id}`} className="title_h3">
            {name}
          </Link>
          {!!address || !!rating ? (
            <div>
              {!!address && (
                <Link
                  // to="/"
                  className="agency-card__address">
                  {address}
                </Link>
              )}

              {!!rating ? <RatingStars value={rating} readOnly={true} /> : null}
            </div>
          ) : null}
        </div>

        {!!Object.values(profiles).length ? (
          <div className="agency-card__counts">
            {!!profiles?.total ? (
              <div className={"agency-card__count"}>
                <Icon size={"l"} spritePath={"body2"} />
                <div className="agency-card__questionnaires">
                  <span>
                    {t("profiles").charAt(0).toUpperCase() +
                      t("profiles").slice(1)}
                  </span>
                  {profiles.total}
                </div>
              </div>
            ) : null}

            {!!profiles?.verified ? (
              <div className={"agency-card__count"}>
                <Icon size={"l"} spritePath={"verify"} />
                <div className="agency-card__verified">
                  <span>{t("verified")}</span>
                  {profiles.verified}
                </div>
              </div>
            ) : null}

            {!!profiles?.online ? (
              <div className={"agency-card__count"}>
                <Icon size={"l"} spritePath={"status"} />
                <div className="agency-card__online">
                  <span>{t("online")}</span>
                  {profiles.online}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        {!!description && <p>{description}</p>}

        <div className="agency-card__buttons">
          {clicked ? (
            <ButtonLink
              href={!!phone ? formatPhoneNumberIntl(phone) : website}
              size={"m"}
              clazz={`${buttonColor} justify-center w-100`}
            >
              {!!phone ? formatPhoneNumberIntl(phone) : website}
            </ButtonLink>
          ) : (
            <Button
              onClick={() => setClicked(true)}
              clazz={`${buttonColor} w-100`}
              size={`m`}
            >
              {t("shownumber")}
            </Button>
          )}
          {!!whatsapp ? (
            <ButtonLink
              href={whatsapp}
              clazz={buttonColor}
              square={true}
              size={"m"}
            >
              <Icon size={"xl"} spritePath={"whatsapp"} />
            </ButtonLink>
          ) : null}

          {!!telegram ? (
            <ButtonLink
              href={`https://t.me/${telegram}`}
              clazz={buttonColor}
              square={true}
              size={"m"}
            >
              <Icon size={"xl"} spritePath={"telegram"} />
            </ButtonLink>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AgencyCard;
