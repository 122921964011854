import { useState } from "react";
import { Button, ErrorMessage, Icon, SelectCountry } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import GeoInput from "@/components/ui/input/GeoInput";

import { convertData } from "@/helper/convertData";
import { Map } from "../../../createProfile/pageComponent";
import { AgencyWrapper } from "./AgencyWrapper";
import { AgencyRowWrapper } from "./AgencyRowWrapper";
import useHandleLocationClick from "@/services/HandleLocationClick";

import "./addressRow.scss";
import showToast from "../../../../../toast/Toast";
import { makeRequest } from "../../../../../../services/makeRequest";

export const AddressRow = ({
  onRemove,
  onChange,
  onAdd,
  cities = [],
  isActive,
  resAdress,
  formCity,
  setFormCity,
  selectedCity,
  setSelectedCity,
  currentActive,
  setCurrentActive,
  setBounds,
  bounds,
  activeCountry,
  setActiveCountry,
  activeAddress,
  setActiveAddress,
  setIsActive,
  addresses,
  setAddresses,
  address,
  mapMarker,
  setMapMarker,
  email,
  phone,
}) => {
  const { t, dispatch, lang, userType } = useLadyService();
  const isAgency = userType === "agency";
  const citiesOptions = convertData(cities);
  const [cityError, setCityError] = useState("");
  // const address = useSelector(getOneProfileAddress);
  const [geoError, setGeoError] = useState(false);

  const handleChangeActive = (e) => {
    e.preventDefault();
    setIsActive(true);
  };
  const [isAddressActive, setIsAddressActive] = useState(false);
  const throwError = (e) => {
    if (
      !e.target.classList.contains("icon--location") &&
      !e.target.classList.contains("button--tetriary")
    ) {
      if (!selectedCity) {
        setGeoError(true);

        setTimeout(() => {
          setGeoError(false);
        }, 2000);
      }
    }
  };

  const handleSubmit = () => {
    const fetchData = async () => {
      try {
        const route = `user`;
        const method = "PUT";
        const payload = {
          email: email,
          phone: phone,
          address: addresses[activeAddress].address,
        };

        const result = await makeRequest({ route, method, payload });
        if (result) {
          setIsActive(false);
          showToast({
            message: t("success"),
            variant: "success",
          });
        }
      } catch (error) {
        const res = error.response;

        // eslint-disable-next-line default-case
        switch (res.status) {
          case 401: {
            showToast({
              message: t("noauth"),
              variant: "error",
            });
            break;
          }
          case 422: {
            showToast({
              message: t("oops"),
              variant: "error",
            });
            break;
          }
        }
      }
    };

    fetchData();
  };

  const AddressItem = ({ data, id, onRemove, onChange }) => {
    const [currentCityAgency, setCurrentCityAgency] = useState(data.city);
    const handleChange = (e) => {
      const { name } = e;
      onChange(id, name ? "city" : "address", name || e);
    };

    const handleLocationClick = useHandleLocationClick(
      setFormCity,
      setActiveCountry,
      setBounds,
      setCurrentActive,
      setSelectedCity,
      activeCountry,
      cities,
      setIsAddressActive,
      setMapMarker
    );

    return (
      <>
        <SelectCountry
          data={citiesOptions}
          name={"create-city"}
          clazz={`${cityError ? "_error" : ""} ${
            !isActive ? "_disabled" : ""
          } size-l-forever justify-start select`}
          clazzSvg={`select__flag ${formCity?.icon || ""}`}
          arrowSize={"m"}
          type={"flag"}
          placeholder={t("yourcity")}
          defaultTitle={
            isAgency ? currentCityAgency : selectedCity || formCity?.name
          }
          subTitle={t("yourcity")}
          // withoutIcon={!selectedCity}
          withoutIcon={isAgency ? !currentCityAgency : !selectedCity}
          // setValue={setSelectedCity}
          setValue={isAgency ? setCurrentCityAgency : setSelectedCity}
          currentActiveIndex={currentActive}
          setActiveCountry={setActiveCountry}
          activeCountry={activeCountry}
          setActive={setCurrentActive}
          error={cityError}
          errorMessage={<ErrorMessage message={cityError} />}
          onChange={(e) => {
            if (isAgency) {
              handleChange(e);
            }
            if (cityError) {
              setCityError("");
            }
          }}
          disabled={!isActive}
          setError={setCityError}
          setIsAddressActive={setIsAddressActive}
        />
        <div className={`search filter__map-input`}>
          <div
            className={`search filter__map-input ${
              selectedCity ? "" : "geo-error"
            } ${!isActive ? "_disabled" : ""}`}
            onClick={(e) => {
              if (isActive) {
                throwError(e);
              }
            }}
          >
            <span className={"filter__map-title"}>{t("youraddress")}</span>
            <label
              htmlFor="geosuggest__input-create"
              className="search__label header__search"
            >
              <GeoInput
                value={data.address}
                setValue={handleChange}
                name={"location-create"}
                id="geosuggest__input-create"
                clazz={`header__search-input ${geoError ? "_error" : ""}`}
                placeholder={t("fulladdress")}
                bounds={bounds}
                isAgency={true}
              />

              {geoError && <ErrorMessage message={t("cityfirst")} />}
            </label>
          </div>
          <Button
            title={t("chekmyplace")}
            onClick={handleLocationClick}
            name={"location"}
            square={true}
            buttonType={"button"}
            size={"l"}
            disabled={!isActive}
            clazz={
              isAddressActive
                ? "search__icon button--tetriary active"
                : "search__icon button--tetriary"
            }
          >
            <Icon size={"xl"} spritePath={"location"} />
          </Button>
          {isAgency && (
            <div
              className={`tarifs-row-trash d-flex align-center cursor-pointer ${
                !isActive ? "disabled" : ""
              }`}
              onClick={() => onRemove(id)}
            >
              <Icon size={"xl"} spritePath={"trash"} clazz={"_red"} />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <AgencyWrapper isActive={isActive} setIsActive={setIsActive} onAdd={onAdd}>
      <div
        className={`info-address ${
          userType === "agency" ? "info-address__agency" : ""
        }`}
      >
        {addresses.length
          ? addresses.map((item, index) => {
              return (
                <AgencyRowWrapper
                  isActive={isActive}
                  index={index}
                  activeAddress={activeAddress}
                  setActiveAddress={setActiveAddress}
                >
                  <AddressItem
                    data={item}
                    id={item.id}
                    onRemove={onRemove}
                    onChange={onChange}
                  />
                  {!isAgency &&
                    activeAddress === index &&
                    selectedCity &&
                    item.city && (
                      <Map
                        clazz={"mt-12"}
                        setMarkerActive={setIsAddressActive}
                        markerActive={isAddressActive}
                        region={cities[activeCountry].name}
                        city={selectedCity}
                        setBounds={setBounds}
                        coords={mapMarker}
                        disabled={!isActive}
                      />
                    )}

                </AgencyRowWrapper>
              );
            })
          : null}
      </div>

      {!isAgency && !isActive && (
        <Button
          size={"s"}
          clazz={
            "button_outline--green-accent justify-center float-right w-160 mt-12"
          }
          onClick={handleChangeActive}
        >
          {t("edit")}
        </Button>
      )}
      {!isAgency && isActive && (
        <Button
          size={"s"}
          buttonType={"button"}
          onClick={() => handleSubmit()}
          clazz={
            "button_outline--green-accent justify-center float-right w-160 mt-12"
          }
        >
          {t("save")}
        </Button>
      )}
    </AgencyWrapper>
  );
};
