import { Route, Routes } from "react-router-dom";
import {
  ErrorPage,
  MainPage,
  RegistrationPage,
  Selection,
  VariantModelPage,
} from "../pages";
import Echo from "laravel-echo";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import { t } from "i18next";

import {
  getUserID,
  setApiUserReady,
  setUserId,
  setUserTariff,
  setUserTariffSlug,
  setUserType,
  setReadyToOrder,
  setReadyToVideochat,
  setFavoriteCounter,
} from "@/stores/slices/userSlice";
import { Suspense, useEffect } from "react";
import AccountAgency from "../pages/accounts/agency/AccountAgency";
import AccountModel from "../pages/accounts/model/AccountModel";
import SignIn from "../signIn/SignIn";
import AgencyList from "../pages/agencyList/AgencyList";
import AgencyPage from "../pages/agency/AgencyPage";
import VideochatHistoryPage from "../pages/videochatHistoryPage/VideochatHistoryPage";
import PartyPage from "../pages/partyPage/PartyPage";
import PrivateRoute from "../private-route/private-route";
import ProfilesPage from "../pages/accounts/model/profiles/ProfilesPage";
import { makeRequest } from "@/services/makeRequest";
import CreateProfile from "../pages/accounts/createProfile/CreateProfile";
import { Loader } from "@/components/ui";
import ReviewsPage from "../pages/accounts/model/reviews/ReviewsPage";
import ReservationPage from "../pages/accounts/model/reservations/ReservationPage";
import BalancePage from "../pages/balancePage/BalancePage";
import StatPage from "../pages/accounts/model/StatPage";
import MainLayout from "../pages/Layouts/MainLayout";
import UIKitPage from "../pages/uIKitPage/UIKitPage";
import About from "../pages/about/About";
import showToast from "../toast/Toast";
import useLadyService from "@/services/LadyService";
import {
  setBookingAmount,
  setEmailVerifyAt,
  setUserBalance,
  setUserBookingBlock,
  setUserHold,
  setUserStatus,
  setUserTickets,
  setUserVideochatBlock,
  setUserVideochatCanceledBlock,
  setVideochatAmount,
  setVideochatStatus,
} from "../../stores/slices/userSlice";
import { setVideoCallStatus } from "../../stores/slices/videoCallSlice";
import PrivacyPolicy from "../pages/rules/RulesPage";
import RulesPage from "../pages/privacyPolicy/PrivacyPolicy";
import SupportPage from "../pages/support/SupportPage";
import Guide from "../pages/guide/Guide";
import ComingSoon from "../pages/ComingSoon";
import Balance from "../pages/balance/Balance";
import ClientPage from "../pages/accounts/client/ClientPage";
import useFetchRanges from "@/hooks/useFetchFilters";
import {
  clearDescByName,
  setTranslateDesc,
  setTranslateDescEro,
  setTranslatePrefer,
} from "@/stores/slices/translateAiSlice";
import WitchdrawBalance from "../pages/witchdrawBalance/WitchdrawBalance";
import { Subscriptions } from "../pages/subscriptions/Subscriptions";
import History from "../pages/history/History";
import { RedirectLk } from "../pages/redirectLk/RedirectLk";
import { AdminLogin } from "../pages/adminLogin/AdminLogin";

function App() {
  const { dispatch, userType, token, lang, navigate } = useLadyService();
  useFetchRanges();
  const urlParams = window.location.pathname;
  useEffect(() => {
    if (urlParams.includes("videochats") || urlParams.includes("videochat")) {
      dispatch(setVideochatStatus(true));
    }
  }, []);

  useEffect(() => {
    if (lang && urlParams.includes("payment")) {
      navigate(`/${lang}/lk/topup`);
    }
  }, [lang]);

  const userId = useSelector(getUserID);
  useEffect(() => {
    if (token && userId && process.env.REACT_APP_WEBSOCKET_ENABLED) {
      window.Echo = new Echo({
        broadcaster: "socket.io",
        client: io,
        host: process.env.REACT_APP_WEBSOCKET_URL,
        auth: { headers: { Authorization: "Bearer " + token } },
        transports: ["websocket"],
      });

      const handleEvent = (eventName, handler) => {
        window.Echo.private(`App.Models.User.${userId}`).listen(
          eventName,
          (e) => {
            const eventKey = `${eventName}-${e.broadcast_event_id}`;

            if (!localStorage.getItem(eventKey)) {
              handler(e);
              localStorage.setItem(eventKey, "true");
            }
          }
        );
      };

      handleEvent("ErrorEvent", (e) => {
        showToast({
          message: t(e.message),
          variant: "error",
          ...(e.message === "insuff" && { type: "insuff" }),
          ...(e.message === "insuff" && { userType: userType }),
        });
      });
      if (userType === "indi") {
        handleEvent("VideoCallEvent", (e) => {
          dispatch(setVideochatAmount((prev) => prev + 1));
          showToast({
            type: "video",
            variant: "video",
          });
          dispatch(setUserVideochatBlock(e));
        });

        handleEvent("VideoCallCanceledEvent", (e) => {
          dispatch(setUserVideochatCanceledBlock(e.data));
          showToast({
            message: t("canceledbyclient"),
            variant: "info",
          });
        });

        handleEvent("DescriptionTranslateEvent", (e) => {
          dispatch(clearDescByName("description_translate_to"));

          if (e["cz"]) {
            dispatch(
              setTranslateDesc({
                cz: e["cz"],
              })
            );
          }
          if (e["ru"]) {
            dispatch(
              setTranslateDesc({
                ru: e["ru"],
              })
            );
          }
          if (e["en"]) {
            dispatch(
              setTranslateDesc({
                en: e["en"],
              })
            );
          }
        });

        handleEvent("EroDescriptionTranslateEvent", (e) => {
          dispatch(clearDescByName("description_ero_translate_to"));

          if (e["cz"]) {
            dispatch(
              setTranslateDescEro({
                cz: e["cz"],
              })
            );
          }
          if (e["ru"]) {
            dispatch(
              setTranslateDescEro({
                ru: e["ru"],
              })
            );
          }
          if (e["en"]) {
            dispatch(
              setTranslateDescEro({
                en: e["en"],
              })
            );
          }
        });

        handleEvent("PreferencesTranslateEvent", (e) => {
          dispatch(clearDescByName("preferences_translate_to"));

          if (e["cz"]) {
            dispatch(
              setTranslatePrefer({
                cz: e["cz"],
              })
            );
          }
          if (e["ru"]) {
            dispatch(
              setTranslatePrefer({
                ru: e["ru"],
              })
            );
          }
          if (e["en"]) {
            dispatch(
              setTranslatePrefer({
                en: e["en"],
              })
            );
          }
        });

        handleEvent("BookingEvent", (e) => {
          dispatch(setBookingAmount((prev) => prev + 1));
          showToast({
            type: "booking",
            variant: "booking",
          });
          dispatch(setUserBookingBlock(e));
        });
      } else {
        handleEvent("VideoCallCanceledEvent", (e) => {
          dispatch(setVideoCallStatus("canceled"));
        });
      }

      handleEvent("BalanceUpdateEvent", (e) => {
        dispatch(setUserBalance(e["amount"]));
        dispatch(setUserHold(e["holden"]));
      });

      handleEvent("TransactionEvent", (e) => {
        showToast({
          type:
            e["data"]["type"] === "credit"
              ? "transaction_credit"
              : "transaction_debit",
          variant: "info",
        });
      });
      window.Echo.join("presence")
        .here((users) => {
          console.log("Currently online users:", users);
        })
        .joining((user) => {
          console.log("User joined:", user);
          makeRequest({
            route: "/api/presence",
            method: "POST",
            payload: { user_id: userId },
          });
        })
        .leaving((user) => {
          console.log("User left:", user);
          makeRequest({
            route: "/api/presence",
            method: "DELETE",
            payload: { user_id: userId },
          });
        });
    }
  }, [dispatch, token, userId, userType]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = "user/me";
        const method = "GET";

        const { data = {} } = await makeRequest({ route, method });
        const userType = data.user_type;
        const userId = data.id;
        const video = data.accept_video;
        const order = data.accept_booking;
        const balance = Math.round(data.balance);
        const favorites = data.favorites_count;
        const video_chats = data.video_chats_count;
        const bookings = data.bookings_count;
        const hold = data.balance_hold;
        const tickets = data.tickets_count;
        const emailVerifyAt = data.email_verified_at;

        dispatch(setVideochatAmount(video_chats));
        dispatch(setBookingAmount(bookings));
        dispatch(setUserType(userType));
        // dispatch(setUserType('agency'));
        dispatch(setUserId(userId));
        dispatch(setUserTariff(data.tariff));
        dispatch(setUserTariffSlug(data.tariff.data.slug));
        dispatch(setApiUserReady(true));
        dispatch(setReadyToOrder(order));
        dispatch(setReadyToVideochat(video));
        dispatch(setUserBalance(balance));
        dispatch(setUserHold(hold));
        dispatch(setFavoriteCounter(favorites));
        dispatch(setUserTickets(tickets));
        dispatch(setEmailVerifyAt(emailVerifyAt));
      } catch (error) {}
    };

    userType === "default" ? token && fetchData() : fetchData();
  }, [token, userType]);

  const userAuth = localStorage.getItem("user");
  useEffect(() => {
    if (!userAuth && userType !== "default") {
      console.log("not auth");

      dispatch(setUserType("default"));
      dispatch(setUserStatus("silver"));
      dispatch(setUserId(null));
      dispatch(setFavoriteCounter(0));
      navigate(`/`);
    }
  }, [userAuth]);

  return (
    <Suspense fallback={<Loader height={100} />}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path=":lang?/:city?/:params?" element={<MainPage />} />

          <Route path=":lang?/registration" element={<RegistrationPage />} />

          <Route path=":lang?/support" element={<SupportPage />} />

          <Route path=":lang?/guide" element={<Guide />} />

          <Route path=":lang?/gdpr" element={<RulesPage />} />

          <Route path=":lang?/rules" element={<PrivacyPolicy />} />

          <Route path=":lang?/about" element={<About />} />

          <Route path=":lang?/lk/photograph" element={<ComingSoon />} />

          <Route path=":lang?/uikit" element={<UIKitPage />} />
          <Route path=":lang?/login/:token" element={<AdminLogin />} />

          <Route path=":lang?/:city/agencies" element={<AgencyList />} />

          <Route
            path=":lang?/profile/:profileSlug"
            element={<VariantModelPage />}
          />

          <Route
            path=":lang?/check-:profileSlug"
            element={<VariantModelPage />}
          />

          <Route path=":lang?/agency/:agencyId" element={<AgencyPage />} />
          

          <Route path=":lang?/party/:partyId" element={<PartyPage />} />

          <Route path=":lang?/call/:room" element={<MainPage />} />

          <Route path=":lang?/selection/:citySlug" element={<Selection />} />

          <Route path=":lang?/lk/support" element={<SupportPage />} />
          {/* lk agency START ---------------------------------------------------  */}
          <Route
            path=":lang?/lk/agency"
            element={
              <PrivateRoute>
                <AccountAgency />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/agency/profiles"
            element={
              <PrivateRoute>
                <ProfilesPage />
              </PrivateRoute>
            }
          />
          {/* lk agency START ---------------------------------------------------  */}

          {/* lk photographer END ---------------------------------------------------  */}
          <Route
            path=":lang?/lk/photographer"
            element={
              <PrivateRoute>
                <ComingSoon />
              </PrivateRoute>
            }
          />
          {/* lk photographer END ---------------------------------------------------  */}

          {/* lk customer START ---------------------------------------------------  */}
          <Route
            path=":lang?/lk/customer"
            element={
              <PrivateRoute>
                <ClientPage />
              </PrivateRoute>
            }
          />

          {/* <Route
                        path=":lang?/lk/customer/history"
                        element={
                            <PrivateRoute>
                                <ComingSoon/>
                            </PrivateRoute>
                        }
                    /> */}

          {/*<Route*/}
          {/*    path=":lang?/lk/customer/balance"*/}
          {/*    element={*/}
          {/*        <PrivateRoute>*/}
          {/*            <ComingSoon/>*/}
          {/*        </PrivateRoute>*/}
          {/*    }*/}
          {/*/>*/}

          <Route
            path=":lang?/lk/customer/subscriptions"
            element={
              <PrivateRoute>
                <Subscriptions />
              </PrivateRoute>
            }
          />
          {/* lk customer END ---------------------------------------------------  */}

          {/* lk indi START ---------------------------------------------------  */}
          <Route
            path=":lang?/lk/indi"
            element={
              <PrivateRoute>
                <AccountModel />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/videochats"
            element={
              <PrivateRoute>
                <VideochatHistoryPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/profiles"
            element={
              <PrivateRoute>
                <ProfilesPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/reviews"
            element={
              <PrivateRoute>
                <ReviewsPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/reservations"
            element={
              <PrivateRoute>
                <ReservationPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/balance"
            element={
              <PrivateRoute>
                <BalancePage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/withdraw"
            element={
              <PrivateRoute>
                <WitchdrawBalance />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/statistic"
            element={
              <PrivateRoute>
                <StatPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/profile/:profileSlug/edit"
            element={
              <PrivateRoute>
                <VariantModelPage edit />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/create"
            element={
              <PrivateRoute>
                <CreateProfile />
              </PrivateRoute>
            }
          />
          <Route path=":lang?/lk" element={<RedirectLk />} />
          <Route
            path=":lang?/lk/indi/create/:slug"
            element={
              <PrivateRoute>
                <CreateProfile />
              </PrivateRoute>
            }
          />
          {/*  lk indi END --------------------------------------------------- */}

          <Route path=":lang?/history" element={<History />} />

          <Route
            path={`:lang?/lk/topup`}
            element={
              <PrivateRoute>
                <Balance />
              </PrivateRoute>
            }
          />

          <Route
              path=":lang?/preview/:profileSlug"
              element={
                <PrivateRoute>
                  <VariantModelPage edit />
                </PrivateRoute>
              }
          />

          <Route
            path={`:lang?/lk/${userType}/balance`}
            element={
              <PrivateRoute>
                <Balance />
              </PrivateRoute>
            }
          />
          <Route
            path={`:lang?/signin/:token`}
            element={
              <PrivateRoute>
                <SignIn />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="404" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
