import React from "react";
import { InputInLabel } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { useForm } from "react-hook-form";
import { Button } from "../../../ui";

export const PersonalData = ({ phone, valueLoad, setIsOpenPassword }) => {
  const { windowWidth, t } = useLadyService();
  const isMobile = windowWidth < 420;
  const { register } = useForm();
  const handleOpenPassword = () => {
    setIsOpenPassword(true);
  };

  return (
    <div className="account-page__identity">
      <h2>{t("personaldata")}</h2>
      <form className="account-page__identity__form">
        <InputInLabel
          type={"tel"}
          id={"phone"}
          placeholder={t("phonenumber")}
          disabled
          register={{ ...register("phone") }}
          maskValue={phone}
          valueLoad={valueLoad}
        >
          {t("phonenumber")}
        </InputInLabel>
        {!isMobile && (
          <span>
            {t("forphonechange")} <a href="/lk/support">{t("toadmin")}</a>
          </span>
        )}

        <Button
          size={"s"}
          clazz={"button_outline--green-accent w-160"}
          onClick={handleOpenPassword}
        >
          {t("changepass")}
        </Button>
      </form>
      {isMobile && (
        <span>
          {t("forphonechange")} <a href="/lk/support">{t("toadmin")}</a>
        </span>
      )}
    </div>
  );
};
