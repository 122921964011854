import axios from "axios";
import baseUrl from "./apiConfig";

export const fetcher = async (args) => {
  const [url, method, params] = args;
  const lang = localStorage.getItem("i18nextLng");

  const token = localStorage.getItem("user");
  const axiosConfig = { method, url: `${baseUrl}${url}` };

  const currentParams = { ...params, lang };

  switch (method) {
    case "GET": {
      Object.entries(currentParams).map(([key, value]) => {
        if (value !== undefined) {
          currentParams[key] = value;
        } else {
          delete currentParams[key];
        }
      });

      axiosConfig.url = Object.keys(currentParams).length
        ? `${axiosConfig.url}?${new URLSearchParams(currentParams)}`
        : axiosConfig.url;
      break;
    }

    case "POST":
    case "DELETE":
    case "PUT": {
      axiosConfig.data = currentParams;
      break;
    }

    default: {
      break;
    }
  }

  if (token) {
    axiosConfig.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return axios(axiosConfig)
    .then(async (res) => {
      const { data } = res;
      return data;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          console.log("BAD URL");
        }
        if (error.response.status === 401) {
          localStorage.removeItem("user");
        }
      }
    });
};
