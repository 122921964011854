import { useEffect, useState } from "react";
import { Button, Tippy } from "@/components/ui";
import { useForm } from "react-hook-form";
import { RegistrationPopup } from "../../popups";
import {
  setRegistrationStatus,
  getRegistrationStatus,
} from "@/stores/slices/popupSlice";
import { setUserType } from "@/stores/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Icon } from "../../ui";

import "./registrationPage.scss";

const RegistrationPage = () => {
  const { register, handleSubmit, getValues } = useForm({
    mode: "onSubmit",
  });
  const dispatch = useDispatch();

  const registrationState = useSelector(getRegistrationStatus);

  const { t } = useTranslation("translation");

  let responce;

  const [dataName, setDataName] = useState();

  const onSubmit = (data, e) => {
    e.preventDefault();
    let result = getValues("account");
    responce = setUserType(result).payload;
    setDataName(result);
    dispatch(setRegistrationStatus(true));
  };
  const isIndi = window.location.hash === "#indi" ? "indi" : "customer";

  useEffect(() => {
    if (isIndi === "indi") {
      const element = document.querySelector(".registration__account");

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }

      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
    }
  }, []);

  const [active, setActive] = useState({
    customer: isIndi === "customer" ? true : false,
    indi: isIndi === "indi" ? true : false,
    agency: false,
    photograph: false,
  });

  return (
    <main>
      <section className={"registration__container"}>
        <h1 className={"text-center mb-40 mb-24-mob"}>{t("registration")}</h1>
        <h2>{t("chooseaccount")}</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="registration__account"
        >
          <div className="registration__radio-group">
            <label
              htmlFor="customer"
              className={`registration__radio${
                active.customer ? " _active" : ""
              }`}
              onClick={() =>
                setActive({
                  customer: true,
                  indi: false,
                  agency: false,
                  photograph: false,
                })
              }
            >
              <span>{t("client")}</span>
              <input
                {...register("account")}
                id={"customer"}
                type="radio"
                value={"customer"}
                defaultChecked={active.customer}
              />
              <p className={"p1"}>{t("advantages")}</p>
            </label>

            <label
              htmlFor="indi"
              className={`registration__radio${active.indi ? " _active" : ""}`}
              onClick={() =>
                setActive({
                  customer: false,
                  indi: true,
                  agency: false,
                  photograph: false,
                })
              }
            >
              <span>{t("indireg")}</span>
              <input
                {...register("account")}
                id={"indi"}
                type="radio"
                value={"indi"}
                defaultChecked={active.indi}
              />
              <p className={"p1"}>{t("registerandearn")}</p>
            </label>

            {/*<label*/}
            {/*  htmlFor="agency"*/}
            {/*  className={`registration__radio${*/}
            {/*    active.agency ? " _active" : ""*/}
            {/*  }`}*/}
            {/*  onClick={() =>*/}
            {/*    setActive({*/}
            {/*      user: false,*/}
            {/*      indi: false,*/}
            {/*      agency: true,*/}
            {/*      photograph: false,*/}
            {/*    })*/}
            {/*  }*/}
            {/*>*/}
            {/*  <span>{t("agencybrothel")}</span>*/}
            {/*  <input*/}
            {/*    {...register("account")}*/}
            {/*    id={"agency"}*/}
            {/*    type="radio"*/}
            {/*    value={"agency"}*/}
            {/*    defaultChecked={active.agency}*/}
            {/*  />*/}
            {/*  <p className={"p1"}>{t("groupsandbrothels")}</p>*/}
            {/*</label>*/}

            {/*<label*/}
            {/*    htmlFor="photograph"*/}
            {/*    className={`registration__radio${*/}
            {/*      active.photograph ? " _active" : ""*/}
            {/*    }`}*/}
            {/*    onClick={() =>*/}
            {/*      setActive({*/}
            {/*        user: false,*/}
            {/*        indi: false,*/}
            {/*        agency: false,*/}
            {/*        photograph: true,*/}
            {/*      })*/}
            {/*    }*/}
            {/*  >*/}
            {/*    <span>{t("photographer")}</span>*/}
            {/*    <input*/}
            {/*      {...register("account")}*/}
            {/*      id={"photograph"}*/}
            {/*      type="radio"*/}
            {/*      value="photograph"*/}
            {/*      defaultChecked={active.photograph}*/}
            {/*    />*/}
            {/*    <p className={"p1"}>{t("eroticphotoshoots")}</p>*/}
            {/*  </label>*/}
          </div>

          <div className="registration__box">
            <div
              className={`registration__description${
                active.customer ? " _active" : ""
              }`}
            >
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("garantie")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("videochatswithmodel")} <span>{t("preintro")}</span>
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("accesstoerocontent")} <span>{t("explicitphotos")}</span>
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("ability")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("severaltopick")} <span>{t("pickbest")}</span>
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("savefilterspecific")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("contactedmodels")} <span>{t("avoidconfusion")}</span>
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("addtofav")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("reviewscomments")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("complainandblock")}
              </p>

              <Tippy title={t("advantages")}>
                <div className="fake-checkbox">
                  <Icon square size={"m"} spritePath={"check"} />
                  {t("invitestoparties")}
                </div>
              </Tippy>
            </div>

            <div
              className={`registration__description${
                active.indi ? " _active" : ""
              }`}
            >
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("freeregpost")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("recommendations")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("moderatorsupport")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("importprofile")}
                <span>{t("createagain")}</span>
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("severalprofiles")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("storiesupload")} <span>{t("promoteprofile")}</span>
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("earnchatsandero")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("receiveorders")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("getverified")}
                <Tippy
                  title={t("check")}
                  className={"registration__tippy text-underline"}
                >
                  {"«" + t("verification") + "»"}
                </Tippy>
                {t("freeorcall")}
              </p>

              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("healthcontroldiscount")}
                <Tippy
                  title={t("chekckid")}
                  className={"registration__tippy text-underline"}
                >
                  {"«" + t("healthy") + "»"}
                </Tippy>
              </p>

              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("detailedstatistics")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("freebackcheck")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("photocatalogue")}
              </p>
              <p className={"p1"}>
                <Icon size={"s"} spritePath={"check"} />
                {t("hideinsearch")}
              </p>

              <div className="fake-checkbox">
                <Icon square size={"m"} spritePath={"check"} />
                {t("elitehighlight")}
              </div>
            </div>

            {/*<div*/}
            {/*  className={`registration__description${*/}
            {/*    active.agency ? " _active" : ""*/}
            {/*  }`}*/}
            {/*>*/}
            {/*  <p className={"p1"}>*/}
            {/*    <Icon size={'s'} spritePath={'check'}/>*/}
            {/*    {t("novatcharge")}.*/}
            {/*  </p>*/}
            {/*  <p className={"p1"}>*/}
            {/*    <Icon size={'s'} spritePath={'check'}/>*/}
            {/*    {t("operators")}*/}
            {/*  </p>*/}
            {/*  <p className={"p1"}>*/}
            {/*    <Icon size={'s'} spritePath={'check'}/>*/}
            {/*    {t("autotranslategen")} <span>{t("addfunctions")}</span>*/}
            {/*  </p>*/}
            {/*  <p className={"p1"}>*/}
            {/*    <Icon size={'s'} spritePath={'check'}/>*/}
            {/*    {t("profilepositions")}*/}
            {/*  </p>*/}
            {/*  <p className={"p1"}>*/}
            {/*    <Icon size={'s'} spritePath={'check'}/>*/}
            {/*    {t("profilestats")}*/}
            {/*  </p>*/}
            {/*  <p className={"p1"}>*/}
            {/*    <Icon size={'s'} spritePath={'check'}/>*/}
            {/*    {t("fasttrackverify" + t("videoverify"))}*/}
            {/*  </p>*/}
            {/*  <p className={"p1"}>*/}
            {/*    <Icon size={'s'} spritePath={'check'}/>*/}
            {/*    {t("sexpartiesorg")}.*/}
            {/*  </p>*/}

            {/*  <div className="fake-checkbox">*/}
            {/*     <Icon square size={"m"} spritePath={"check"} />*/}
            {/*    {t("elitehighlight")}*/}
            {/*  </div>*/}

            {/*  <span>{t("indifeatures")}</span>*/}

            {/*  <div className="registration__inner">*/}
            {/*    <div className="registration__content">*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("freeregpost")}*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("recommendations")}*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("moderatorsupport")}*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("importprofile")}*/}
            {/*        <span> {t("createagain")}</span>*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("severalprofiles")}*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("storiesupload")} <span>{t("promoteprofile")}</span>*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("earnchatsandero")}*/}
            {/*      </p>*/}
            {/*    </div>*/}

            {/*    <div className="registration__content">*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("receiveorders")}*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("getverified")}*/}
            {/*        <Tippy title={t("check")} className={"registration__tippy"}>*/}
            {/*          {"«" + t("verification") + "»"}*/}
            {/*        </Tippy>*/}
            {/*        {t("freeorcall")}*/}
            {/*      </p>*/}

            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("healthcontroldiscount")}*/}
            {/*        <Tippy*/}
            {/*          title={t("chekckid")}*/}
            {/*          className={"registration__tippy"}*/}
            {/*        >*/}
            {/*          {"«" + t("healthy") + "»"}*/}
            {/*        </Tippy>*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("detailedstatistics")}*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("freebackcheck")}*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("photocatalogue")}*/}
            {/*      </p>*/}
            {/*      <p className={"p1"}>*/}
            {/*        <Icon size={'s'} spritePath={'check'}/>*/}
            {/*        {t("hideinsearch")}*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div*/}
            {/*      className={`registration__description${*/}
            {/*          active.photograph ? " _active" : ""*/}
            {/*      }`}*/}
            {/*  >*/}
            {/*    <p className={"p1"}>*/}
            {/*      <Icon size={'s'} spritePath={'check'}/>*/}
            {/*      {t('reganddisplay')}.*/}
            {/*    </p>*/}
            {/*    <p className={"p1"}>*/}
            {/*      <Icon size={'s'} spritePath={'check'}/>*/}
            {/*      {t('webportfolio')}{" "}*/}
            {/*      <span>*/}
            {/*      {t('videopres')}*/}
            {/*    </span>*/}
            {/*    </p>*/}
            {/*    <p className={"p1"}>*/}
            {/*      <Icon size={'s'} spritePath={'check'}/>*/}
            {/*      {t('ratingsreviews')}*/}
            {/*    </p>*/}

            {/*    <div className="fake-checkbox">*/}
            {/*      <Icon square size={"m"} spritePath={"check"} />*/}
            {/*      {t('regandearnnow')}*/}
            {/*    </div>*/}
            {/*  </div>*/}

            <Button buttonType={"submit"} clazz={"button-l-forever button--green"}>
              {t("registration")}
            </Button>
          </div>
        </form>
      </section>

      {!!registrationState && (
        <RegistrationPopup text={active} dataName={dataName} />
      )}
    </main>
  );
};

export default RegistrationPage;
