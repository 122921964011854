import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  setForgotPasswordStatus,
  getAuthorizationStatus,
  setAuthorizationStatus,
  getVideoChatMode,
  setVideoChatMode,
  getFilterMode,
  setFilterMode,
  setMapMode,
  getErocontentAccessStatus,
  setErocontentAccessStatus,
  getAviableForUsersStatus,
  setAviableForUsersStatus,
  setAuthorisationAccessType,
  getAuthorisationAccessType,
} from "@/stores/slices/popupSlice";
import { Button, ButtonLink, Icon, InputInLabel } from "@/components/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import axios from "axios";

import "./authorization.scss";
import { setUserType, setUserId } from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import baseUrl from "@/services/apiConfig";
import { setAfterAuth } from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import {Popup} from "../index";
import { LoadingButton } from "../../ui";

const Authorization = () => {
  const { t, dispatch } = useLadyService();
  const isVideoChatMode = useSelector(getVideoChatMode);
  const isFilterMode = useSelector(getFilterMode);

  const isErocontentAccessMode = useSelector(getErocontentAccessStatus);
  const isAviableForUsers = useSelector(getAviableForUsersStatus);
  const [formErrors, setFormErrors] = useState({});
  const authorizationValue = useSelector(getAuthorizationStatus);
  const authorisationAccessTypeValue = useSelector(getAuthorisationAccessType);

  const toggleChange = (e) => {
    let value = e.target.value;
    setValue("login", value.trim());
    if (/^\d/.test(value)) {
      const result = (value = "" + value);
      e.target.value = result;
    }
  };


  const formSchema = object().shape({
    login: mixed()
      .test("email-or-phone-not-empty", `${t("loginisness")}`, (value) => {
        return value !== "";
      })
      .test("is-email-or-phone", `${t("badformat")}`, (value) => {
        const phoneRegex = /^\+?\d+$/;
        const isPhone = phoneRegex.test(value.replace(/\s/g, ""));

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmail = emailRegex.test(value);

        return isPhone || isEmail;
      }),
    password: string().required(`${t("passisness")}`),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const [loading, setLoading] = useState(false)

  const uuiCode = localStorage.getItem("uui");
  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${baseUrl}auth/login`, {
        identity: data.login.replace(/[" "+]/g, ""),
        password: data.password,
        ...(uuiCode && { publish_code: uuiCode }),
      })
      .then((response) => {
        if (response.status === 200) {
          if (isErocontentAccessMode) {
            dispatch(setErocontentAccessStatus(true));
          }
          const token = response.data.token;
          localStorage.setItem("user", token);
          axios
            .request({
              headers: {
                Authorization: `Bearer ${token}`,
              },
              method: "GET",
              url: `${baseUrl}user/me`,
            })
            .then((response) => {
              if (response.status === 200) {
                localStorage.removeItem("uui")
                const userType = response.data.data.user_type;
                const userId = response.data.data.id;
                dispatch(setUserType(userType));
                dispatch(setUserId(userId));
                dispatch(setAfterAuth(true));

                try {
                  const currentToken = localStorage.getItem("user");

                  const arrFavorites = JSON.parse(
                    localStorage.getItem("favorites")
                  );

                  if (!!arrFavorites.length) {
                    axios
                      .request({
                        headers: {
                          Authorization: `Bearer ${currentToken}`,
                        },
                        method: "POST",
                        url: `${baseUrl}user/favorites/import`,
                        data: {
                          profiles: arrFavorites,
                        },
                      })
                      .then((response) => {
                        if (response.status === 200) {
                          localStorage.removeItem("favorites");
                        }
                      });
                  }
                } catch (error) {}
              }
            })
            .catch((error) => {
              console.error(error);
            });
          onClose();
        }
      })
      .catch((error) => {
        if (error.response.status === 503) {
          setFormErrors({
            login: {
              type: "is-email-or-phone",
              message: t("wrongpass"),
            },
          });
        }
        if (error.response.status === 500) {
          setFormErrors({
            login: {
              type: "is-email-or-phone",
              message: t("somethingwrong"),
            },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();
  const navigateToRegistration = () => {
    dispatch(setAuthorizationStatus(false));
    navigate(`/registration`);
  };
  const onClose = () => {
    reset();
    dispatch(setAuthorizationStatus(false));
    dispatch(setVideoChatMode(false));
    dispatch(setFilterMode(false));
    dispatch(setMapMode(false));
    dispatch(setAviableForUsersStatus(false));
    dispatch(setAuthorisationAccessType(""));
    setFormErrors({});
  };

  const navigateToForgorPass = () => {
    reset();
    dispatch(setForgotPasswordStatus(true));
    dispatch(setAuthorizationStatus(false));
  };

  const checkTitle = () => {
    if (isVideoChatMode) {
      return t("videochat");
    } else if (authorisationAccessTypeValue === "ero") {
      return t("erocontent");
    } else if (authorisationAccessTypeValue === "support") {
      return t("support");
    } else if (authorisationAccessTypeValue === "note") {
      return t("note");
    } else if (authorisationAccessTypeValue === "order") {
      return t("onlineorder");
    } else if (authorisationAccessTypeValue === "interaction") {
      return t("adsinteraction");
    } else if (authorisationAccessTypeValue === "complain") {
      return t("complain");
    } else if (authorisationAccessTypeValue === "review") {
      return t("writereview");
    } else {
      return t("login");
    }
  };
  const [passValue, setPassValue] = useState("");
  useEffect(() => {
    if (authorizationValue) {
      setValue("password", passValue);
    }
  }, [authorizationValue, passValue]);

  return (
    <>
      <Popup
        open={!!authorizationValue}
        setOpen={onClose}
        id={"authorization"}
        wrapperClazz="authorization"
        clazz={'gap-8'}
        onSubmit={handleSubmit(onSubmit)}
      >
          <h2>{checkTitle()}</h2>
          {isVideoChatMode && (
            <>
              <p className="p2 text-center">
                <b>{t("aviableforusers")}</b>
              </p>
              <p className="p2 text-center">{t("meetmodelonline")}</p>
            </>
          )}
          {isFilterMode && (
            <p className="p2 text-center">
              <b>{t("savefiltersincab")}</b>
            </p>
          )}
          {(isAviableForUsers ||
            authorisationAccessTypeValue === "ero" ||
            authorisationAccessTypeValue === "note" ||
            authorisationAccessTypeValue === "support" ||
            authorisationAccessTypeValue === "order" ||
            authorisationAccessTypeValue === "interaction" ||
            authorisationAccessTypeValue === "complain" ||
            authorisationAccessTypeValue === "review") && (
            <p className="p2 text-center">
              <b>{t("aviableforusers")}</b>
            </p>
          )}
          <div className="popup-form__inner">
            <InputInLabel
              clazz={`authorization__password${errors.login ? " _error" : ""}`}
              id={"loginForm"}
              title={t("loginuser")}
              type={"text"}
              autocomplete={"login"}
              onChange={(e) => {
                if (errors.login) {
                  clearErrors("login");
                }
                if (formErrors?.login?.message) {
                  setFormErrors({});
                }
                clearErrors("root");
                toggleChange(e);
              }}
              placeholder={"example@google.com"}
              register={{ ...register("login") }}
            >
              <span>
                {t("loginuser") +
                  " (" +
                  t("youremail") +
                  " " +
                  t("or") +
                  " " +
                  t("phonenumber").toLowerCase() +
                  ")"}
              </span>
            </InputInLabel>

            <InputInLabel
              clazz={`authorization__password${
                errors.password ? " _error" : ""
              }`}
              id={"passwordForm"}
              title={t("pass")}
              type={"password"}
              onChange={(e) => {
                if (errors.password) {
                  clearErrors("password");
                }
                if (formErrors?.login?.message) {
                  setFormErrors({});
                }
                clearErrors("root");
                setPassValue(e.target.value);
              }}
              autocomplete={"password"}
              placeholder={"**********"}
              register={{ ...register("password") }}
            />

            {(errors.password && (
              <p className={"popup-form__error"}>{errors.password.message}</p>
            )) ||
              ((formErrors.login || errors.login) && (
                <p className={"popup-form__error"}>
                  {formErrors.login?.message || errors.login.message}
                </p>
              ))}
            <LoadingButton
              isLoading={loading}
              size={"l-forever"}
              buttonType={"submit"}
              clazz={"button--green authorization__submit"}
            >
              {t("login")}
            </LoadingButton>

            <button
              type={"button"}
              className={"authorization__forgot"}
              onClick={() => navigateToForgorPass()}
            >
              {t("recoverpassword") + "?"}
            </button>
          </div>
          <hr />
          <div className="popup-form__box">
            <span className={"popup-form__box-title"}>{t("noaccount")}</span>
            {isAviableForUsers ? (
              <ButtonLink
                size={"l-forever"}
                target={"_blank"}
                href={`/registration`}
                clazz={"button_outline--green"}
                onClick={() => onClose()}
              >
                {t("register")}
              </ButtonLink>
            ) : (
              <Button
                size={"l-forever"}
                clazz={"button_outline--green"}
                onClick={() => navigateToRegistration()}
              >
                {t("register")}
              </Button>
            )}
          </div>
      </Popup>
    </>
  );
};

export default Authorization;
