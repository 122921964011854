import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  adult: Cookies.get("adult-popup") === "false" ? false : true,
  registration: false,
  changePhoto: false,
  authorization: false,
  videoChatMode: false,
  filterMode: false,
  mapMode: false,
  forgotPassword: false,
  forgotPasswordEmail: false,
  forgotPasswordPhone: false,
  forgotPasswordPhoneInputs: false,
  startVideochat: false,
  stories: false,
  erocontentAccess: false,
  appeal: false,
  successAppeal: false,
  addReview: false,
  orderModel: false,
  map: false,
  partyAccess: false,
  gallery: {
    init: false,
    mediaIndex: 0,
    swiperRef: null,
    media:{
      photo: 0,
      video: 0,
      interior: 0,
      erocontent: {
        photo: 0,
        video: 0,
      }
    },
  },
  viewing: false,
  newPass: false,
  aviableForUsers: false,
  authorisationAccessType: '',
  afterAuth: false,
  requestVerStatus: false,
  fastVerification: false,
  addOrImportProfile: false,
  addStories: false,
};

export const popupSlice = createSlice({
  name: "popups",
  initialState,
  reducers: {
    setAddOrImportProfile: (state, action) => {
      state.addOrImportProfile = action.payload
    },
    setChangePhoto: (state, action) => {
      state.changePhoto = action.payload
    },
    setFastVerStatus: (state, action) => {
      state.fastVerification = action.payload
    },
    setRequestVerStatus: (state, action) => {
      state.requestVerStatus = action.payload
    },
    setMapMode: (state, action) => {
      state.mapMode = action.payload;
    },
    setVideoChatMode: (state, action) => {
      state.videoChatMode = action.payload;
    },
    setFilterMode: (state, action) => {
      state.filterMode = action.payload;
    },
    hideAdult: (state, action) => {
      state.adult = action.payload;
      Cookies.set("adult-popup", action.payload, { expires: 60 });
    },

    setRegistrationStatus: (state, action) => {
      state.registration = action.payload;
    },

    setAuthorizationStatus: (state, action) => {
      state.authorization = action.payload;
    },

    setAfterAuth: (state, action) => {
      state.afterAuth = action.payload;
    },

    setForgotPasswordStatus: (state, action) => {
      state.forgotPassword = action.payload;
    },

    setForgotPasswordEmailStatus: (state, action) => {
      state.forgotPasswordEmail = action.payload;
    },

    setForgotPasswordPhoneStatus: (state, action) => {
      state.forgotPasswordPhone = action.payload;
    },

    setForgotPasswordPhoneInputsStatus: (state, action) => {
      state.forgotPasswordPhoneInputs = action.payload;
    },

    setStartVideochatStatus: (state, action) => {
      state.startVideochat = action.payload;
    },

    setStoriesStatus: (state, action) => {
      state.stories = action.payload;
    },

    setErocontentAccessStatus: (state, action) => {
      state.erocontentAccess = action.payload;
    },

    setAppealStatus: (state, action) => {
      state.appeal = action.payload;
    },
    setSuccessAppealStatus: (state, action) => {
      state.successAppeal = action.payload;
    },
    setAddReviewStatus: (state, action) => {
      state.addReview = action.payload;
    },
    setOrderModelStatus: (state, action) => {
      state.orderModel = action.payload;
    },

    setStoriesMap: (state, action) => {
      state.map = action.payload;
    },
    setPartyAccess: (state, action) => {
      state.partyAccess = action.payload;
    },
    setGalleryStatus: (state, action) => {
      state.gallery.init = action.payload;
    },
    setGalleryMediaIndex: (state, action) => {
      state.gallery.mediaIndex = action.payload;
    },
    setGalleryMediaPhoto: (state, action) => {
      state.gallery.media.photo = action.payload;
    },
    setGalleryMediaVideo: (state, action) => {
      state.gallery.media.video = action.payload;
    },
    setGalleryMediaInterior: (state, action) => {
      state.gallery.media.interior = action.payload;
    },
    setGalleryMediaEroPhoto: (state, action) => {
      state.gallery.media.erocontent.photo = action.payload;
    },
    setGalleryMediaEroVideo: (state, action) => {
      state.gallery.media.erocontent.video = action.payload;
    },
    setGallerySwiperRef: (state, action) => {
      state.gallery.media.swiperRef = action.payload;
    },

    setViewingStatus: (state, action) => {
      state.viewing = action.payload;
    },
    setNewPassStatus: (state, action) => {
      state.newPass = action.payload;
    },
    setAviableForUsersStatus: (state, action) => {
      state.aviableForUsers = action.payload;
    },
    setAuthorisationAccessType: (state, action) => {
      state.authorisationAccessType = action.payload;
    },
    setAddStoriesStatus: (state, action) => {
      state.addStories = action.payload;
    },
  },
});

export const {
  setChangePhoto,
  setAfterAuth,
  setMapMode,
  setFilterMode,
  setVideoChatMode,
  hideAdult,
  setRegistrationStatus,
  setAuthorizationStatus,
  setForgotPasswordStatus,
  setForgotPasswordEmailStatus,
  setForgotPasswordPhoneStatus,
  setForgotPasswordPhoneInputsStatus,
  setStartVideochatStatus,
  setStoriesStatus,
  setErocontentAccessStatus,
  setAppealStatus,
  setSuccessAppealStatus,
  setAddReviewStatus,
  setOrderModelStatus,
  setStoriesMap,
  setPartyAccess,
  setGalleryStatus,
  setGallerySwiperRef,
  setGalleryMediaIndex,
  setGalleryMediaPhoto,
  setGalleryMediaVideo,
  setGalleryMediaInterior,
  setGalleryMediaEroPhoto,
  setGalleryMediaEroVideo,
  setViewingStatus,
  setNewPassStatus,
  setAviableForUsersStatus,
  setAuthorisationAccessType,
  setRequestVerStatus,
  setFastVerStatus,
  setAddOrImportProfile,
  setAddStoriesStatus,
} = popupSlice.actions;

export const getAdultState = (state) => state.popups.adult;

export const getAddOrImportProfileState = (state) => state.popups.addOrImportProfile;

export const getChangePhotoStatus = (state) => state.popups.changePhoto;

export const getRequestVerStatus = (state) => state.popups.requestVerStatus;

export const getFastVerStatus = (state) => state.popups.fastVerification;

export const getRegistrationStatus = (state) => state.popups.registration;

export const getAuthorizationStatus = (state) => state.popups.authorization;

export const getVideoChatMode = (state) => state.popups.videoChatMode;

export const getMapMode = (state) => state.popups.mapMode;

export const getFilterMode = (state) => state.popups.filterMode;

export const getForgotPasswordStatus = (state) => state.popups.forgotPassword;

export const getForgotPasswordEmailStatus = (state) =>
  state.popups.forgotPasswordEmail;

export const getForgotPasswordPhoneStatus = (state) =>
  state.popups.forgotPasswordPhone;

export const getForgotPasswordPhoneInputsStatus = (state) =>
  state.popups.forgotPasswordPhoneInputs;

export const getStartVideochatStatus = (state) => state.popups.startVideochat;

export const getStoriesStatus = (state) => state.popups.stories;

export const getErocontentAccessStatus = (state) =>
  state.popups.erocontentAccess;

export const getAppealStatus = (state) => state.popups.appeal;
export const getSuccessAppealStatus = (state) => state.popups.successAppeal;

export const getAddReviewStatus = (state) => state.popups.addReview;

export const getOrderModelStatus = (state) => state.popups.orderModel;

export const getMapStatus = (state) => state.popups.map;

export const getPartyAccessStatus = (state) => state.popups.partyAccess;

export const getGalleryStatus = (state) => state.popups.gallery.init;
export const getGalleryIndexMedia = (state) => state.popups.gallery.mediaIndex;
export const getGalleryMediaPhoto = (state) => state.popups.gallery.media.photo;
export const getGalleryMediaVideo = (state) => state.popups.gallery.media.video;
export const getGalleryMediaInterior = (state) => state.popups.gallery.media.interior;
export const getGalleryMediaEroPhoto = (state) => state.popups.gallery.media.erocontent.photo;
export const getGalleryMediaEroVideo = (state) => state.popups.gallery.media.erocontent.video;

export const getViewingStatus = (state) => state.popups.viewing;

export const getNewPassStatus = (state) => state.popups.newPass;

export const getAfterAuth = (state) => state.popups.afterAuth;

export const getAviableForUsersStatus = (state) => state.popups.aviableForUsers;

export const getAuthorisationAccessType = (state) => state.popups.authorisationAccessType;

export const getAddStoriesStatus = (state) => state.popups.addStories;
export default popupSlice.reducer;
