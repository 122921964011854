import useLadyService from "@/services/LadyService";
import { Button } from "@/components/ui";
import { Icon } from "@/components/ui";

export const AgencyWrapper = ({
  children,
  isActive,
  setIsActive,
  onAdd

}) => {
  const { userType, t } = useLadyService();

  const handleChange = (e) => {
    e.preventDefault();
    setIsActive(true);
  };

  if (userType === "agency") {
    return (
      <>
        <div className="d-flex justify-sb align-center">
          <h3>{t("youraddress")}</h3>
          <div className="d-flex gap-16">
            <Button
              size={"s"}
              clazz={
                "button_outline--green-accent justify-center float-right"
              }
              onClick={onAdd}
            >
              <Icon spritePath={"plus"} size={"s"} />

              {t("addad") + " " + t("fulladdress").split(" ")[1]}
            </Button>
            {!isActive ? (
              <Button
                size={"s"}
                clazz={
                  "button_outline--green-accent justify-center float-right w-160"
                }
                onClick={handleChange}
              >
                {t("edit")}
              </Button>
            ) : (
              <Button
                size={"s"}
                buttonType={"submit"}
                clazz={
                  "button_outline--green-accent justify-center float-right w-160"
                }
              >
                {t("save")}
              </Button>
            )}
          </div>
        </div>
        {children}
      </>
    );
  } else {
    return <>{children}</>;
  }
};
