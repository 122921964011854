import { Link, useNavigate } from "react-router-dom";

import { ButtonLink, Icon, LogoSvg } from "@/components/ui";

import { setAuthorizationStatus } from "@/stores/slices/popupSlice";
import { setAuthorisationAccessType } from "@/stores/slices/popupSlice";
import { resetAllCounters } from "@/stores/slices/counterFilterSlice";

import "./footer.scss";
import React from "react";
import { useSelector } from "react-redux";
import { getUserCurrentCity } from "@/stores/slices/userSlice";
import {
  cleanSorted,
  getNotDisabledCheckbox,
  setPageDefault,
  setSearchValue,
  setSorted,
  setSortedModelsByFilter,
} from "@/stores/slices/modelsState";
import { resetAll, setSearchFilter } from "@/stores/slices/filterSlice";
import { clearTitle } from "@/stores/slices/titleSlice";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";
import { getUserRegion } from "../../stores/slices/userSlice";
import {
  defaultRangeState,
  toggleSelected,
  toggleState,
} from "../../stores/slices/filterSlice";
import { toggleStateCount } from "../../stores/slices/counterFilterSlice";
import { setTitle } from "../../stores/slices/titleSlice";

import solana from "@/assets/img/icons/solana.svg";
import { rangeChangeForModel } from "../../stores/slices/modelsState";
import { setProfileFilterCount } from "../../stores/slices/mapModelSlice";

const paymentsMethods = [
  { icon: "visa", name: "Visa" },
  { icon: "master-card", name: "MasterCard" },
  { icon: "american-express", name: "AmericanExpress" },
  { icon: "paypal", name: "PayPal" },
  { icon: "google-pay", name: "Google Pay" },
  { icon: "apple-pay", name: "Apple Pay" },
  { icon: "union-pay", name: "Union Pay" },
  { icon: "bitcoin", name: "Bitcoin" },
  { icon: "tether", name: "Tether" },
  { icon: "usdc", name: "USDC" },
  { icon: "etherium", name: "Ethereum" },
  { icon: "ltc", name: "Litecoin" },
  { icon: "matic", name: "Matic" },
  { icon: "tron", name: "Tron" },
];

const Footer = () => {
  const { dispatch, userType, lang, city, scrollToTop } = useLadyService();

  const url = window.location.pathname;

  const region = useSelector(getUserRegion);
  const { t } = useTranslation("translation");

  const cityCurrent = useSelector(getUserCurrentCity);

  const postTitleFooter = t("titlefooter")?.replace(
    /%([^%]+)%/g,
    (match, p1) => {
      switch (p1) {
        case "City":
          return cityCurrent.title;
        case "Country":
          return region;
        default:
          return match;
      }
    }
  );

  const categoryLinks = [
    {
      id: 1,
      link: "/indi",
      title: t("indi"),
      name: "status",
      forDisabled: "statusIndividual",
    },
    {
      id: 2,
      link: "/elite",
      title: t("elite"),
      name: "status",
      forDisabled: "statusElite",
    },
    {
      id: 3,
      link: "/pornstar",
      title: t("star"),
      name: "status",
      forDisabled: "statusPornstar",
    },
    {
      id: 4,
      link: "/online",
      title: t("online"),
      name: "status",
      forDisabled: "statusOnline",
    },
    {
      id: 8,
      link: "/new",
      title: t("newmodels"),
      name: "status",
      forDisabled: "statusNew",
    },
    {
      id: 11,
      link: "/bdsm",
      title: t("bdsm"),
      name: "status",
      forDisabled: "statusBDSM",
    },
    {
      id: 10,
      link: "/couple",
      title: t("couples"),
      name: "status",
      forDisabled: "genderCouple",
    },
    {
      id: 12,
      link: "/trance",
      title: t("trans"),
      name: "gender",
      forDisabled: "genderTrans",
    },
    {
      id: 13,
      link: "/guys",
      title: t("guys"),
      name: "gender",
      forDisabled: "genderMale",
    },
    {
      id: 5,
      link: "/vchat",
      title: t("videochat"),
      name: "status",
      forDisabled: "tagVideochat",
    },
    {
      id: 55,
      link: "/ero",
      title: t("erocontent"),
      name: "status",
      forDisabled: "tagEro",
    },
    {
      id: 6,
      link: "/verified",
      title: t("verification"),
      name: "status",
      forDisabled: "statusVerification",
    },
    {
      id: 7,
      link: "/healthy",
      title: t("healthy"),
      name: "status",
      forDisabled: "statusHealthControl",
    },
  ];

  const infoLinks = {
    top_links: [
      { id: 1, link: `/${lang}/${city}`, name: t("mainpage") },
      { id: 2, link: `/about`, name: t("about") },
      { id: 3, link: `/guide`, name: t("guide") },
      // { id: 4, link: `/faq`, name: t("faq") },
      { id: 5, link: `/lk/support`, name: t("support") },
    ],

    bottom_links: [
      { id: 1, link: `/rules`, name: t("termsofservice") },
      { id: 2, link: `/gdpr`, name: t("privacypolicy") },
    ],
  };
  const defaultRangeValue = useSelector(defaultRangeState);

  const handleLogo = () => {

    dispatch(cleanSorted());
    dispatch(resetAll());
    dispatch(clearTitle());
    dispatch(setSearchFilter(false));
    dispatch(setSearchValue(""));
    dispatch(setPageDefault());
    dispatch(setSortedModelsByFilter(""));
    dispatch(resetAllCounters());
    dispatch(setProfileFilterCount(null));

    dispatch(
      rangeChangeForModel({
        name: "price",
        min: defaultRangeValue.price.min,
        max: defaultRangeValue.price.max,
      })
    );
    dispatch(
      rangeChangeForModel({
        name: "age",
        min: defaultRangeValue.age.min,
        max: defaultRangeValue.age.max,
      })
    );

    scrollToTop()
  };


  const { keysWithValueTrue } = useSelector(getNotDisabledCheckbox);

  const footerLink = (e, link, name) => {
    // e.preventDefault();

    dispatch(cleanSorted());
    // navigate(`/${city}${link}`);
    dispatch(toggleStateCount({ StateName: name, count: link.slice(1) }));

    if (name === "status") {
      dispatch(toggleSelected({ value: link.slice(1), name: "status" }));
      dispatch(
        setSorted({
          stateName: "sorted",
          payload: link.slice(1),
        })
      );
      dispatch(setTitle(link.slice(1)));
      dispatch(toggleState({ value: link.slice(1), StateName: "status" }));
    }
    if (name === "gender") {
      let result = "";
      switch (link.slice(1)) {
        case "guys":
          result = "male";
          break;
        case "trance":
          result = "trans";
          break;
        case "couple":
          result = "couple";
          break;
        default:
          result = "";
      }
      dispatch(
        setSorted({
          stateName: "sorted",
          payload: result,
        })
      );
      dispatch(toggleSelected({ value: result, name: "status" }));
      dispatch(toggleSelected({ value: result, name: "gender" }));
      dispatch(setTitle(result));
      dispatch(toggleState({ value: result, StateName: "gender" }));
    }

    scrollToTop()
  };

  return (
    <footer>
      <div className="footer__container">
        <Link
          to={`/`}
          className="logo"
          title={postTitleFooter}
          onClick={() => handleLogo()}
        >
          <LogoSvg alt={t("altfooter")} />

          <p>Lady<span className={'color-green'}>4</span>Love - make a sensible choice</p>
        </Link>

        <nav className="footer-categories">
          <h3 className="footer__title">{t("categories")}</h3>
          <div className="footer-categories__body">
            {categoryLinks.map((i) => {
              return (
                <Link
                  to={`/${city}${i.link}`}
                  key={i.id}
                  title={i.title}
                  className={`hover ${
                    keysWithValueTrue?.length > 0 &&
                    keysWithValueTrue.includes(i.forDisabled)
                      ? ""
                      : "disabled"
                  } ${url.includes('lk') && userType === "indi" ? "disabled" : ""}`}
                  onClick={(e) => footerLink(e, i.link, i.name)}
                >
                  {i.title}
                </Link>
              );
            })}
            <Link
              to={`/selection/${city}`}
              title={t("selectionmodels")}
              size={"xs"}
              className={`hover ${url.includes('lk') && userType === "indi" ? "disabled" : ""}`}
            >
              {t("selectionmodels")}
            </Link>
            {userType === "default" ? (
              <ButtonLink
                href={`/registration#indi`}
                title={t("add")}
                size={"xs"}
                clazz={"add-form button_outline--green hover"}
              >
                {t("add")}
                <Icon size={"s"} spritePath={"plus"} />
              </ButtonLink>
            ) : null}
          </div>
        </nav>

        <div className="footer-info">
          <h3 className="footer__title">
            {t("forusers")} <Icon spritePath={"18-plus"} />
          </h3>
          <div className="footer-info__top-links">
            {infoLinks.top_links.map((i) => {
              if (i.id === 5 && userType === "default") {
                return (
                  <Link
                    to={`${i.link}`}
                    onClick={() => {
                      dispatch(setAuthorisationAccessType("support"));
                      dispatch(setAuthorizationStatus(true));
                      if(i.id === 1) {
                        scrollToTop()
                      }
                    }}
                    key={i.id}
                    title={i.name}
                    className="hover"
                  >
                    {i.name}
                  </Link>
                );
              }

              return (
                <Link
                  to={`${i.link}`}
                  key={i.id}
                  title={i.name}
                  className="hover"
                >
                  {i.name}
                </Link>
              );
            })}
          </div>
          <div className="footer-info__bottom-links">
            {infoLinks.bottom_links.map((i) => {
              return (
                <Link className={"hover"} to={`${i.link}`} key={i.id} title={i.name}>
                  {i.name}
                </Link>
              );
            })}
          </div>
          <div className="payment-methods">
            {paymentsMethods.map((i, index) => {
              return (
                <div
                  className="payment-methods__icon"
                  key={index + 1}
                  title={i.name}
                >
                  <Icon type={"payment"} spritePath={i.icon} />
                </div>
              );
            })}

            <div title={"Solana"} className="payment-methods__icon">
              <img className={"w-100 height-100"} src={solana} alt={"Solana"} />
            </div>

            <div className="payment-methods__icon" title={t("czechbank")}>
              <Icon size={"m"} spritePath={"bank"} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
