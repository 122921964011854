import React, { useEffect, useRef, useState } from "react";
import { Toggle } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import showToast from "@/components/toast/Toast";
import { Link, useLocation } from "react-router-dom";
import { makeRequest } from "../../../../services/makeRequest";

export const NotifyBlock = ({
  email_verified_at,
  tariffsRef = false,
  setActiveWaOpen = false,
  notify_email,
  notify_telegram,
  notify_whatsapp,
  setNotifyEmail,
  setNotifyTelegram
}) => {

  const { t, userType, userTariffSlug, windowWidth } = useLadyService();
  const isIndi = userType === "indi";
  const isAgency = userType === "agency";
  const notifyRef = useRef(null);
  const { hash } = useLocation();

  const [emailNotifyError, setEmailNotifyError] = useState(false)

  const isMobile = windowWidth < 767.98;

  const handleActiveNotify = async (type) => {
    if(type === "email" && !email_verified_at) {
      setEmailNotifyError(true)
      return
    }
    if(emailNotifyError) {
      setEmailNotifyError(false)
    }
    try {
      const route = `user/notify/${type}`;
      const method = "POST";
      const payload = {
        status: type === "email" ? !notify_email : !notify_telegram,
      };
      const { success, link, message } = await makeRequest({
        route,
        method,
        payload,
      });
      if (success) {
        if (type === "email") {
          setNotifyEmail(prev => !prev)
        } else {
          setNotifyTelegram(prev => !prev)
        }
        showToast({
          message: t("success"),
          variant: "success",
        });
      } else if (message === "need_link") {
        if (typeof window !== "undefined") {
          window.open(link, "_blank");
        }
      } else {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
      
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const [clientWaWarning, setClientWaWarning] = useState(false);

  const handleActiveNotifyWa = () => {
    if (!isIndi) {
      if (userTariffSlug === "silver") {
        setClientWaWarning(true);
      }
    } else {
      if (setActiveWaOpen) {
        setActiveWaOpen(true);
      }
    }
  };

  const toTariffs = (e) => {
    e.preventDefault();

    if (tariffsRef && tariffsRef.current) {
      tariffsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const mainText = isIndi || isAgency ? t("placefornotices") : t("clientnotices");

  useEffect(() => {
    if (notifyRef && notifyRef.current && hash === "#notify") {
      notifyRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [notifyRef]);

  return (
    <div className="notify__block">
      <span className="color-main">{mainText}</span>
      <div
        ref={notifyRef}
        className={`notify__items`}
      >
        <div className="d-flex align-center gap-8 email-notify">
          <h3>Email</h3>
          <Toggle
            id={"isNotifyEmail"}
            checked={!!notify_email}
            onClick={() => handleActiveNotify("email")}
          />
          {(isIndi || isAgency) && t("free")}
          {emailNotifyError && <span className="error color-red-700">{'Email ' + t('notconfirmed')}</span>}
        </div>
        <div className="d-flex align-center gap-8">
          <h3>Telegram</h3>
          <Toggle
            id={"isNotifyTelegram"}
            checked={notify_telegram}
            onClick={() => handleActiveNotify("telegram")}
          />
          {(isIndi || isAgency) && t("free")}
        </div>
        <div
          className={`d-flex align-center gap-8`}
        >
          <h3>WhatsApp</h3>
          <Toggle
            disabled={true}
            id={"isNotifyWa"}
            checked={notify_whatsapp}
            onClick={handleActiveNotifyWa}
          />
          {(isIndi || isAgency) && (
            <div className="d-flex align-center">
              <span className="currency--erocoin">{isIndi ? "20" : "50"}</span>
              {`/${t("month")}`}
            </div>
          )}
          {clientWaWarning && (
            <span>
              {t("wanotices")}
              {" \u2013 "}
              <Link to={`/lk/customer`} onClick={(e) => toTariffs(e)}>
                {t("changetariff")}
              </Link>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
