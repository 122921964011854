import { useDispatch, useSelector } from "react-redux";
import {
  getForgotPasswordPhoneInputsStatus,
  setForgotPasswordPhoneInputsStatus,
  setNewPassStatus,
  setForgotPasswordPhoneStatus,
} from "@/stores/slices/popupSlice";
import { number, object } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { setNewToken } from "@/stores/slices/userSlice";
import { makeRequest } from "@/services/makeRequest";
import "./restorePhoneInputsPopup.scss";
import { t } from "i18next";
import { Popup } from "../index";
import showToast from "@/components/toast/Toast";

import ConfirmPhone from "../../confirmPhone/ConfirmPhone";

const RestorePhoneInputsPopup = ({ telephone }) => {
  const dispatch = useDispatch();
  const showForgotPasswordPhoneInputs = useSelector(
    getForgotPasswordPhoneInputsStatus
  );
  const [codeError, setCodeError] = useState("");
  const formSchema = object().shape({
    phone: number()
      .typeError(t("phonenumerrequired"))
      .required(t("phonenumerrequired")),
  });

  const {
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [codeStatus, setCodeStatus] = useState(false);
  const [code, setCode] = useState("");


  const onSubmit = () => {
    const fetchData = async () => {
      try {
        const route = `auth/password/verify`;
        const method = "POST";
        const payload = {
          identity: parseInt(telephone.replace(/\s/g, "")),
          code: otp.join(""),
        };

        const data = await makeRequest({ route, method, payload });

        if (data?.result) {
          dispatch(setNewToken(data.token));
          dispatch(setForgotPasswordPhoneInputsStatus(false));
          dispatch(setNewPassStatus(true));
          reset();
        } else {
          setCodeError(t("codeisincorrect"));
        }
        // setIsLoading(false);
      } catch (error) {
        if (error.errors) {
          setCodeError(t("codeisincorrect"));
        }
      }
    };

    fetchData();
  };
  const postPhone = t("ifphoneexist")?.replace(/%([^%]+)%/g, telephone);
  const [otp, setOtp] = useState([""]);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if(isComplete) {
      onSubmit();
    }
  }, [isComplete])



  return (
    <>
      {!!showForgotPasswordPhoneInputs && (
        <Popup
          id={"forgotPasswordPhonePopupInputs"}
          open={!!showForgotPasswordPhoneInputs}
          setOpen={() => {
            dispatch(setForgotPasswordPhoneStatus(false));
          }}
          wrapperClazz="forgot-phone-inputs"
          method={"POST"}
        >
          <p className={"popup-form__text"}>
            {postPhone} {t("smscode")}
            {"."}
          </p>

          <ConfirmPhone codeStatus={codeStatus} setFormCodeError={setCodeError} telephone={telephone.replace(/[" "+]/g, "")} setCode={setCode} error={codeError} setCodeStatus={setCodeStatus}/>

        </Popup>
      )}
    </>
  );
};
export default RestorePhoneInputsPopup;
