import { useDispatch, useSelector } from "react-redux";
import { getViewingStatus, setViewingStatus } from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import React, { useState } from "react";
import { number, object } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./orderViewing.scss";
import { getSelectedCount } from "@/stores/slices/agencyFilterSlice";
import {Popup} from "../index";

const OrderViewing = (props) => {
  const { price = 20 } = props;

  const [phoneValue, setPhoneValue] = useState("");

  const dispatch = useDispatch();
  const viewingValue = useSelector(getViewingStatus);

  const selectedCount = useSelector(getSelectedCount);

  const { t } = useTranslation("translation");

  const formSchema = object().shape({
    phone: number()
      .typeError("Телефон обязателен")
      .required("Телефон обязателен"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    console.log(data);
    dispatch(setViewingStatus(false));
    reset();
  };

  const onClose = () => {
    reset();
    dispatch(setViewingStatus(false));
  };

  return (
    <Popup
      open={!!viewingValue}
      setOpen={onClose}
      id={"viewedModel"}
      wrapperClazz="viewed-model"
      clazz={'gap-20'}
      onSubmit={handleSubmit(onSubmit)}
    >
        <h3 className="popup-form__title m-12-24">{t("numberofmodels")}</h3>

        <div className="popup-form__inner fd-row gap-8">
          <InputInLabel id={"viewedDate"} placeholder={"12.02"}>
            {t("date")}
          </InputInLabel>

          <InputInLabel id={"viewedTime"} placeholder={"18:00"} type={"date"}>
            {t("time")}
          </InputInLabel>
        </div>

        <InputInLabel
          id={"orderAddress"}
          placeholder={t("fulladdress")}
          register={{ ...register("address") }}
        >
          {t("youraddress")}
        </InputInLabel>

        <div className="filter__checkbox-buttons gap-10">
          <InputInLabel
            clazz={`${errors.phone ? " error" : ""}`}
            id={"orderPhone"}
            type={"tel"}
            register={{ ...register("phone") }}
            autocomplete={"tel"}
            maskValue={phoneValue}
            setMaskValue={setPhoneValue}
          >
            {(errors.phone && (
              <span className={"input-label__error"}>
                {errors.phone.message}
              </span>
            )) || <span>{t("yourphone")}</span>}
          </InputInLabel>

          <div className={"d-flex gap-4"}>
            <InputInLabel
              clazz={"fd-row"}
              id={"orderWhatsApp"}
              type={"checkbox"}
              register={{ ...register("social") }}
              value={"whatsapp"}
            >
              <Icon spritePath={"whatsapp"} size={"m"} />
            </InputInLabel>
            <InputInLabel
              clazz={"fd-row"}
              id={"orderTelegram"}
              type={"checkbox"}
              register={{ ...register("social") }}
              value={"telegram"}
            >
              <Icon spritePath={"telegram"} size={"m"} />
            </InputInLabel>
          </div>
        </div>
        <div className="popup-form__inner fd-row gap-8 color-700">
          <span className="agency-filter__count w-50">
            {t("numberofmodels")}
            <span className="title title_h2">{selectedCount}</span>
          </span>
          <span
            className={
              "d-flex fd-column gap-4 align-center justify-center p2 w-50"
            }
          >
            {t("priceforselected")}
            <span className="title title_h2">{price * selectedCount} € </span>
          </span>
        </div>

        <p className={"p1 fz-13"}>
          Вся сумма перечисляется агентству, когда оно подтвердит бронь. В
          случае отмены заказа со стороны агентства, эта сумма вернётся на Ваш
          баланс.
          <br />
          *Это оплата услуги Просмотр моделей. Стоимость оказания эскорт-услуг
          обговаривается с выбранной моделью.
        </p>

        <Button
          size={"l-forever"}
          buttonType={"submit"}
          clazz={"button--green"}
        >
          Заказать и оплатить
        </Button>
    </Popup>
  );
};
export default OrderViewing;
