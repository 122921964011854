import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { makeRequest } from "@/services/makeRequest";

import useLadyService from "@/services/LadyService";

import ModelPage from "./ModelPage";
import { Loader } from "@/components/ui";
import {
  setOneProfileAddress,
  setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice";

const VariantModelPage = ({ edit }) => {
  const { lang, navigate, dispatch, scrollToTop } = useLadyService();
  const { profileSlug } = useParams();

  const [oneProfileData, setOneProfileData] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const [stories, setStories] = useState(oneProfileData?.stories);

  const [cities, setCities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    if (edit) {
      const fetchData = async () => {
        try {
          setIsLoading(true);

          const route = `user/profiles`;

          const method = "GET";
          const payload = {
            slug: profileSlug,
          };

          const data = await makeRequest({ route, method, payload });

          if (data) {
            const result = data.profiles;

            setOneProfileData(result[0]);
            setStories(result[0].stories);
            dispatch(setOneProfileAddress(result[0].address));
            dispatch(
              setOneProfileCoords({
                latitude: result[0]?.latitude,
                longitude: result[0]?.longitude,
              })
            );
          }
          setIsLoading(false);
        } catch (error) {}
      };
      profileSlug && lang && fetchData();
    } else {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const route = `profiles/${profileSlug}`;
          const method = "GET";

          const data = await makeRequest({ route, method });
          if (!data) {
            navigate("/404");
          }

          if (data.message === "already_checked") {
            navigate("/404");
          }

          if (data) {
            const result = data.data;
            setOneProfileData(result);
          }

          setIsLoading(false);
        } catch (error) {
          console.log("error", error);
        }
      };
      profileSlug && lang && fetchData();
    }
  }, [profileSlug, lang]);

  useEffect(() => {
    if (edit) {
      const fetchData = async () => {
        try {
          const route = `services/all`;
          const method = "GET";
          const payload = { lang };

          const { cities, languages, nationalities, services } =
            await makeRequest({ route, method, payload });
          setServices(services);
          setNationalities(nationalities);
          setLanguages(languages);
          setCities(cities);
        } catch (error) {}
      };

      lang && fetchData();
    }
  }, [lang]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (isLoading) {
    return <Loader height={100} />;
  } else {
    return (
      <ModelPage
        oneProfileData={oneProfileData}
        props={{
          edit,
          cities,
          languages,
          nationalities,
          services,
          stories,
          setStories,
        }}
      />
    );
  }
};

export default VariantModelPage;
