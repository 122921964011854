import {useTranslation} from "react-i18next";
import {
  Button,
  Icon,
  InputInLabel,
} from "@/components/ui";
import {Popup} from "../index";
import {ExternalLink} from "@/components/ui";
import {Fragment, useState} from "react";
import ImportAdComponent from "@/helper/ImportAdComponent";
import useLadyService from "@/services/LadyService";
import {ButtonLink, Dropdown, LoadingButton} from "../../ui";

const options = [
  {name: "banging.cz"},
  {name: "secretgirlprague.com"},
  {name: "eurogirlsescort.com"},
  {name: "escorts.cz"},
];

const ImportAdPopup = (props) => {
  const {open, setOpen} = props;
  const [importAdValue, setImportAdValue] = useState("");
  const [loading, setLoading] = useState(false);
  const {importAd} = ImportAdComponent()
  const [importFieldError, setImportFieldError] = useState(false);
  const {t} = useLadyService()

  const handleImport = async () => {
    const result = await importAd(importAdValue, setLoading);
    if (result === "error-empty") {
      setImportFieldError(true);

      return
    }
    if (result) {
      setOpen(false);
    }
  };

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      clazz={'gap-16'}
    >

      <h3 className="m-12-24">{t("importfor")}</h3>
      <div className="popup-form__inner">
        <div className="popup-form__inner text-center">
          <p className={"color-700 p2"}>{t("parametrs")}</p>
        </div>

        <Dropdown
          title={t("avaliblesites")}
          name={"site"}
          iconClass={'info'}
          iconSize={'m'}
          clazz={"add__select"}
          openOnHover
          size={"l"}
        >
          {options.map((i, index) => {
            return (
              <Fragment key={index}>
                <ExternalLink
                  clazz={'button button-xs _no-underline justify-start'}
                  exact
                  path={`/${i.name}`}
                  to={`https://${i.name}`}
                />
              </Fragment>
            );
          })}
        </Dropdown>

        <InputInLabel
          type={"text"}
          size={"l"}
          id={"import-ad-popup"}
          placeholder={t('add__link')}
          value={importAdValue}
          onChange={(e) => {
            if (importFieldError) {
              setImportFieldError(false)
            }
            setImportAdValue(e.target.value);
          }}
          clazz={`w-100 link ${importFieldError ? "error" : ""}`}
        >
          {importFieldError ? t("required") : t("url")}
        </InputInLabel>
        <LoadingButton
          isLoading={loading}
          size={"l-forever"}
          clazz={"button--green justify-center mt-16"}
          onClick={() => handleImport()}
        >
          {t("import")}
        </LoadingButton>
      </div>
    </Popup>
  );
};

export default ImportAdPopup;
