import { useEffect, useState } from "react";

import useTitle from "@/hooks/useTitle";
import useLadyService from "@/services/LadyService";
import { BalanceTubs } from "./BalanceTubs";
import { MainForm } from "./BalanceForm/MainForm";

import "./topup.scss";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";

const NewBalance = () => {
  const {
    t,
    scrollToTop,
  } = useLadyService();
  const url = window.location.pathname

  const tubOptions = [
    {
      type: "card",
      title: t("bycard") + " " + t("online"),
      mobileTitle: t("bycard"),
      commission: "6",
      commissionRate: 0.06,
      commissionText: t("fee") + " Emerchant",
      iconPath: "card",
      creditType: t("moment"),
      formTitle: t("bycard") + " " + t("online"),
    },
    {
      type: "paypal",
      title: "PayPal " + t('or') + " " + t("linkdecard"),
      mobileTitle: "Paypal",
      commission: "5",
      commissionRate: 0.05,
      commissionText: t("fee") + " PayPal",
      iconPath: "paypal",
      creditType: t("moment"),
      formTitle: "PayPal " + t('or') + " " + t("linkdecard"),
    },
    {
      type: "crypto",
      title: t("i-crypto"),
      mobileTitle: t("crypto"),
      commission: "1",
      commissionRate: 0.01,
      commissionText: t("fee") + " Confirmo",
      iconPath: "bitcoin",
      creditType: t("moment"),
      formTitle: t("i-crypto"),
    },
    {
      type: "bank",
      title: t("toacc") + " " + t("inbank") + " " + t('or') + " IBAN",
      mobileTitle: t("toacc"),
      commission: "",
      commissionRate: 0,
      commissionText: t("freeoffee"),
      iconPath: "bank",
      creditType: t("earingin") + " 1-2 " + t("workdays"),
      formTitle: t("toacc") + " " + t("inbank") + " " + t('or') + " IBAN",
    },
  ];

  const fetchInvoicesStatus = async (id, payment) => {
    try {
      const route = `payment/status/${id}`;
      const method = "POST";

      const result = await makeRequest({ route, method });

      if (result) {
        
      }
    } catch (error) {
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  }
  
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if(url.includes('method')) {
        // fetchInvoicesStatus()
    }
  }, [])

  useTitle(t("balance"));

  const [active, setActive] = useState("paypal");

  return (
    <main>
      <section className="registration__container">
        <h1 className="text-center">{t("topupbalance")}</h1>
        <h2>{t("selectmeth")}</h2>
        <div className="registration__account">
          <BalanceTubs
            tubOptions={tubOptions}
            active={active}
            setActive={setActive}
          />
          <MainForm tubOptions={tubOptions} type={active} />
        </div>
      </section>
    </main>
  );
};
export default NewBalance;
