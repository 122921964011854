import { Popup } from "../index";
import useLadyService from "@/services/LadyService";
import { makeRequest } from "@/services/makeRequest";
import showToast from "@/components/toast/Toast";
import { useState } from "react";
import { Button, Checkbox, Icon, SelectCountry, Toggle } from "../../ui";
import { useSelector } from "react-redux";
import {
  getCountriesData,
  getUserCurrentCity,
} from "@/stores/slices/userSlice";
import { plural } from "@/helper/plural";

const AgencyFilter = (props) => {
  const { resetFilter, open, setOpen, genderChecked, setGenderChecked, activeToggle, setActiveToggle, extraChecked, setExtraChecked } = props;
  const { t, lang } = useLadyService();

  const options = {
    gender: [
      { value: "female", title: t("woman"), id: "female" },
      { value: "male", title: t("guys"), id: "male" },
      { value: "trans", title: t("trans"), id: "trans" },
      { value: "couple", title: t("couple"), id: "couple" },
    ],
    status: [
      { value: "elitelady", title: t("elite"), id: "elitelady" },
      { value: "activepromo", title: t("activepromo"), id: "activepromo" },
      { value: "videochat", title: t("videochat"), id: "videochat" },
      { value: "onlineorder", title: t("onlineorder"), id: "onlineorder" },
      {
        value: "modelpreviews",
        title: t("modelpreviews"),
        id: "modelpreviews",
      },
      { value: "erocontent", title: t("erocontent"), id: "erocontent" },
      { value: "video", title: t("video"), id: "video" },
      { value: "indi", title: t("indi"), id: "indi" },
      { value: "star", title: t("star"), id: "star" },
      { value: "verified", title: t("verified"), id: "verified" },
      { value: "healthy", title: t("healthy"), id: "healthy" },
      { value: "newmodels", title: t("newmodels"), id: "newmodels" },
    ],
    extra: [
      { value: "withoutprice", title: t("withoutprice"), id: "withoutprice" },
      {
        value: "withoutaddress",
        title: t("withoutaddress"),
        id: "withoutaddress",
      },
    ],
  };

  const countriesData = useSelector(getCountriesData);
  const currentCity = useSelector(getUserCurrentCity);

  const [searchCount, setSearchCount] = useState("");
  const [currentActive, setCurrentActive] = useState("");

  const toggleExtra = (value) => {
    if (extraChecked.includes(value)) {
      setExtraChecked((prev) => prev.filter((item) => item !== value));
    } else {
      setExtraChecked((prev) => [...prev, value]);
    }
  };
  const toggleGender = (value) => {
    if (genderChecked.includes(value)) {
      setGenderChecked((prev) => prev.filter((item) => item !== value));
    } else {
      setGenderChecked((prev) => [...prev, value]);
    }
  };

  const fetchData = async () => {
    try {
      const route = `tags`;
      const method = "POST";
      const payload = {};

      const result = await makeRequest({ route, method, payload });
      if (result) {
        showToast({
          message: t("success"),
          variant: "success",
        });
      }
    } catch (error) {
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };
  
  const handleSubmit = () => {
    fetchData();
  };

  const AgencyFilterStatus = ({ title, value, active, setActive }) => {
    const handleToggle = (value) => {
      if (active.includes(value)) {
        setActive((prev) => prev.filter((item) => item !== value));
      } else {
        setActive((prev) => [...prev, value]);
      }
    };

    return (
      <div className="d-flex justify-sb w-100 align-center">
        {title}
        <Toggle
          clazz={"ml-auto"}
          id={"isNotifyEmail"}
          checked={!!active.includes(value)}
          onClick={() => handleToggle(value)}
        />
      </div>
    );
  };

  const GenderFilter = () => {
    return (
      <div className="filter__box filter--place">
        <span className={"filter__title"}>
          {t("gender").split(" ")[0]}
          {!!genderChecked.length && (
            <span className={"filter__selected"}>!</span>
          )}
          <button
            type="button"
            className={"range__default"}
            onClick={() => setGenderChecked([])}
          >
            {!!genderChecked.length && t("reset")}
          </button>
        </span>
        <div className="filter__checkboxes-grid">
          {options.gender.map((i) => {
            return (
              <Checkbox
                onClick={() => toggleGender(i.value)}
                checked={genderChecked.includes(i.value)}
                key={i.id}
                name={i.name}
                id={i.id}
                title={i.title}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      wrapperClazz={"filter"}
      method={"POST"}
      clazz={"gap-16 filter__body"}
    >
      <div className="popup-form__inner gap-0">
        <div className="filter__top">
          <SelectCountry
            data={countriesData}
            name={"city-create-filter"}
            clazz={`button button-xs select-city`}
            clazzWrapper={`mr-auto`}
            arrowSize={"m"}
            type={"flag"}
            defaultTitle={currentCity.title}
            currentActiveIndex={currentActive}
            isMain={true}
            activeCountry={currentCity.icon}
            setActive={setCurrentActive}
            isAgencyFilter={true}
          />
        </div>
        <GenderFilter />
        <div className="filter__box">
          <span className={"filter__title"}>
            <div className="d-flex">
              {t("Status")}
              {(!!activeToggle.length || !!extraChecked.length) && (
                <span className={"filter__selected"}>!</span>
              )}
              <button
                type="button"
                className={"range__default ml-auto"}
                onClick={() => {
                  setActiveToggle([]);
                  setExtraChecked([]);
                }}
              >
                {!!activeToggle.length && t("reset")}
              </button>
            </div>
          </span>

          <div className="model__params agency__params-filter">
            {options.status.map((i) => {
              return (
                <AgencyFilterStatus
                  value={i.value}
                  title={i.title}
                  active={activeToggle}
                  setActive={setActiveToggle}
                />
              );
            })}
          </div>
          <div className="d-flex gap-16 w-100">
            {options.extra.map((i) => {
              return (
                <Checkbox
                  size={"s"}
                  buttonCheckbox={true}
                  clazz="w-100"
                  title={i.title}
                  onClick={() => toggleExtra(i.value)}
                  checked={extraChecked.includes(i.value)}
                ></Checkbox>
              );
            })}
          </div>
        </div>
        <div className="filter__bottom ">
          <Button
            onClick={() => {}}
            buttonType={"submit"}
            size={"m"}
            clazz={"button--green filter__submit"}
          >
            {`${t("show")} ${searchCount} ${plural(lang, searchCount)}`}
          </Button>
          <Button
            onClick={() => resetFilter()}
            size={"s"}
            clazz={"button--secondary filter__reset"}
          >
            {t("reset")}
          </Button>
          <Button
            size={"s"}
            clazz={"button--tetriary"}
            onClick={() => setOpen(false)}
          >
            {t("toclose")}
            <Icon size={"l"} spritePath={"close"} />
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default AgencyFilter;
