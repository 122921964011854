import React, { useEffect } from "react";
import useLadyService from "@/services/LadyService";
import {getScrolledClass, setScrolledClass} from "@/stores/slices/headerSlice";
import {useSelector} from "react-redux";
import {
  Default,
  IndiLK,
  ModelOrAgency,
  UserOrPhotograph,
  AgencyLK,
  ModerOrAdmin, ModerOrAdminLK
} from "./variants";
import "./header.scss";

const Header = ({lk}) => {
  const {
    dispatch,
    windowWidth,
    userType,
  } = useLadyService();

  const scrolledClass = useSelector(getScrolledClass);

  useEffect(() => {
    if (windowWidth > 767.98) {
      window.addEventListener("scroll", () => {
        if (window.scrollY < 1) {
          dispatch(setScrolledClass(""))
        } else {
          dispatch(setScrolledClass(" _scroll"))
        }
      });
    }
  }, [windowWidth, window]);

  return (
    <header
      className={`${headerClassByUserType()}${
        scrolledClass && windowWidth > 767.98 ? ` ${scrolledClass}` : ""
      }`}
    >
      <div className="header__container">{headerContentByUserType()}</div>
    </header>
  );

  function headerClassByUserType() {
    switch (userType) {
      case "photograph":
      case "customer": {
        return "header--type-one";
      }
      case "agency":
      case "indi": {
        return "header--type-two";
      }
      default: {
        return "";
      }
    }
  }

  function headerContentByUserType() {
    switch (userType) {
      case "photograph":
      case "customer": {
        return <UserOrPhotograph/>;
      }

      case "indi": {
        return lk ? <IndiLK/> : <ModelOrAgency/>;
      }

      case "admin":
      case "moderator": {
        return lk ? <ModerOrAdminLK/> : <ModerOrAdmin/>;
      }

      case "agency": {
        return lk ?  <AgencyLK/> : <ModelOrAgency/>;
      }

      default: {
        return <Default/>;
      }
    }
  }
};
export default Header;
