import { useSelector } from 'react-redux';
import { filterPlace } from '@/stores/slices/filterSlice';
import { getSearchValue } from '@/stores/slices/modelsState';
import { useEffect } from 'react';
import { showFilter } from '@/stores/slices/showFilterSlice';
import { getIsMainPage } from '@/stores/slices/modelsState';
import useLadyService from '../services/LadyService';

const useNavigateToMain = () => {
  const {lang, city, navigate} = useLadyService();
  const placeValue = useSelector(filterPlace);
  const searchValue = useSelector(getSearchValue);
  const filterOpen = useSelector(showFilter);

  const isMainPage = useSelector(getIsMainPage);

  useEffect(() => {
    if(!filterOpen && (!!placeValue.length || !!searchValue)) {
      if(!isMainPage) {
        console.log('navigate-to-main');
        if(lang === "en" && city === "prague") {
          navigate('/');
        } else {
          navigate(`/${lang}/${city}`);
        }
      }
    }
    
  }, [placeValue, searchValue]);

  return null;
};

export default useNavigateToMain;
