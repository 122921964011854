import { Outlet } from "react-router-dom";
import { Favicon } from "@/components/ui";
import Filter from "../../filter/Filter";
import {
  Authorization,
  Adult,
  RestorePasswordPopup,
  StartVideochat,
  CreateVideoCallRoom,
  AfterAuth,
} from "../../popups";
import VideoChat from "../../videoChat/VideoChat";
import { useSelector } from "react-redux";
import { getAdultState } from "@/stores/slices/popupSlice";

import MapFilter from "../../mapFilter/MapFilter";

import NewPassPopup from "../../popups/newPass/NewPass";
import { showFilter } from "@/stores/slices/showFilterSlice";
import { getAuthorizationStatus } from "@/stores/slices/popupSlice";
import { getCreateVideoRoomModalStatus } from "@/stores/slices/videoCallSlice";
import { getNewPassStatus } from "@/stores/slices/popupSlice";
import { getStartVideochatStatus } from "@/stores/slices/popupSlice";
import { getVideoCallModalStatus } from "@/stores/slices/videoCallSlice";
import { getMapStatus } from "@/stores/slices/popupSlice";
import { getAfterAuth } from "@/stores/slices/popupSlice";

import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { getIsMainPage } from "@/stores/slices/modelsState";
import { useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import { setCountriesData, setCountry, setCurrentCity, setLanguagesWords, setNationalistWords, setRegion, setServicesWords } from "@/stores/slices/userSlice";
import { setProfileCount } from "@/stores/slices/mapModelSlice";
import { Helmet } from "react-helmet";

const Layout = ({ titleParams, postDesc }) => {
  const {dispatch, navigate, city, lang} = useLadyService()
  const adultPopupState = useSelector(getAdultState);
  const filterValue = useSelector(showFilter);
  const authorizationValue = useSelector(getAuthorizationStatus);
  const modalStatus = useSelector(getCreateVideoRoomModalStatus);
  const statusNewPass = useSelector(getNewPassStatus);
  const videochatStatus = useSelector(getStartVideochatStatus);
  const modalStatusCall = useSelector(getVideoCallModalStatus);
  const mapPopupStatus = useSelector(getMapStatus);
  const afterAuthStatus = useSelector(getAfterAuth);
  const isMainPage = useSelector(getIsMainPage);

  const url = window.location.pathname;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = "services";
        const method = "GET";

        makeRequest({ route, method }).then((response) => {
          const data = response.data;
          if (!!Object.values(data).length) {
            const citiesData = data.cities;
            const languages = data.languages;
            const nationalities = data.nationalities;
            const services = data.services;

            dispatch(setLanguagesWords(languages));
            dispatch(setNationalistWords(nationalities));
            dispatch(setServicesWords(services));
            const segments = url.split("/");
            const citySegment = segments[2] || "prague";
            if (
              (isMainPage || url.includes("lk/customer") || url.includes('profile')) &&
              citySegment !== "call" &&
              citySegment !== "cancel"
            ) {
              const country = Object.keys(citiesData)[0];
              let cityExist = isMainPage ? false : true;

              const cities = citiesData[country].cities.map((item) => {
                if (!cityExist && item.slug === citySegment) {
                  cityExist = true;
                }
                return {
                  id: item.id,
                  title: item.name,
                  icon: citiesData[country].code.toUpperCase(),
                  highlight: item.highlight,
                  slug: item.slug,
                  profiles_count: item.profiles_count,
                  sort: item.sort,
                };
              });
              if (!cityExist) {
                console.log("cityExist", cityExist);
                navigate("/404");
                return;
              }
              const currentCity = cities.filter((item) => {
                return item.slug === citySegment;
              });
              dispatch(setCountry(cities));
              dispatch(setCountriesData(citiesData));

              if (!!currentCity?.length) {
                dispatch(setProfileCount(currentCity[0].profiles_count));
                dispatch(setCurrentCity(currentCity[0]));
                dispatch(setRegion(citiesData[country].name));
              }
            } else {
              if(!city) {
                dispatch(setCurrentCity({
                  title: citiesData['cz'].cities.filter(item => item.slug === "prague")[0].name,
                  id: "1",
                  icon: "cz"
                }));
                dispatch(setCountriesData(citiesData));

              }
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    lang && !url.includes('lk/indi') &&  fetchData();
  }, [lang, isMainPage]);

  return (
    <>
      <Favicon />
      {!url.includes('404') && !url.includes('lk') && (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}

      <Header />
      <Outlet />
      <Footer />


      {!!filterValue && <Filter />}
      {!!authorizationValue && <Authorization />}
      <RestorePasswordPopup />
      {!!statusNewPass && <NewPassPopup />}
      {!!modalStatus && <CreateVideoCallRoom />}
      {!!videochatStatus && <StartVideochat />}
      {!!modalStatusCall && <VideoChat />}
      {!!mapPopupStatus && <MapFilter />}
      {!!adultPopupState && <Adult />}
      {!!afterAuthStatus && <AfterAuth />}
    </>
  );
};

export default Layout;
