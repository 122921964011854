import {
  ButtonLink,
  Icon,
  SwitchTheme,
  Burger,
} from "@/components/ui";
import useLadyService from "@/services/LadyService";
import {
  BurgerMenu,
  DropdownLanguage,
  HeaderTabs, InstructionLink,
  Logo,
  MenuNavBar,
  OrderAndVidChatToggles,
  SupportButton,
  UserWrapperDesktop
} from "../components";

import "../header.scss";

const ModerOrAdminLK = () => {
  const { windowWidth, t, } = useLadyService();

  if (windowWidth > 1199.98)
    return (
      <>
        <div className="header__top">
          <DropdownLanguage />

          <SwitchTheme type={"switch"} />
        </div>

        <div className="header__bottom">
          <Logo/>

          <div className={"header__tabs"}>
            <HeaderTabs lk/>
          </div>

          <UserWrapperDesktop lk/>
        </div>
      </>
    );

  if (windowWidth > 767.98)
    return (
      <div className="header__top">
        <Logo/>

        <div className="user__wrapper">

          <Burger />

          <DropdownLanguage size={"s"} />

          <SwitchTheme type={"switch"} />

          <BurgerMenu lk />
        </div>
      </div>
    );

  return (
    <>
      <div className="header__top">
        <div className="user__wrapper">
          <Burger />

          <DropdownLanguage />
        </div>

        <Logo/>

        <div className="user__wrapper">
          <SwitchTheme type={"switch"} />
        </div>

        <BurgerMenu lk />

        <MenuNavBar lk/>
      </div>
    </>
  );
};
export default ModerOrAdminLK;