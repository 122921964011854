import { useState, useEffect } from "react";

import { ResContainer } from "./pageComponents";
import { makeRequest } from "@/services/makeRequest";

import useLadyService from "../../../../../services/LadyService";
import Pagination from "../../../../pagination/Pagination";
import { useSelector } from "react-redux";
import { getUserBookingBlock } from "../../../../../stores/slices/userSlice";
import { CustomSkeleton } from "../../../../ui";
import { useLocation, useNavigate } from "react-router-dom";

const skeletons = [...Array(12)].map((_, index) => {
  return <CustomSkeleton key={index} height={150} />;
});

const Res = ({ isLoading, data, setData }) => {
  const { t } = useLadyService();
  switch (true) {
    case isLoading: {
      return skeletons;
    }

    case !!data?.length: {
      return data?.map((i) => {
        return <ResContainer key={i.id} data={i} setData={setData} />;
      });
    }

    default: {
      return <h3 className="text-center mt-12">{t("emptyrecord")}</h3>;
    }
  }
};

const ReservationPage = () => {
  const { token, lang, t, scrollToTop } = useLadyService();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const { hash } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(1);

  const newBookingBlock = useSelector(getUserBookingBlock);

  const [pagesList, setPagesList] = useState([]);

  useEffect(() => {
    if (!!data.length) {
      setData((prev) => {
        return [newBookingBlock.data, ...prev];
      });
    }
  }, [newBookingBlock]);

  const handlePage = (e, value) => {
    e.preventDefault();
    navigate(`/${lang}/lk/indi/reservations#${value}`);
    setPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        scrollToTop();
        const route = `bookings`;
        const method = "GET";
        const payload = {
          limit: 12,
          page,
        };

        const { bookings, meta } = await makeRequest({
          route,
          method,
          payload,
        });

        if (!!bookings.length) {
          const { per_page, total } = meta;
          const result = Math.ceil(total / per_page);
          const arrayRes = Array.from({ length: result }, (_, i) => i + 1);
          setPagesList(arrayRes);

          setData(bookings);
        }

        setIsLoading(false);
      } catch (error) {}
    };

    page !== 0 && token && fetchData();
  }, [token, page]);

  useEffect(() => {
    let str = hash;
    str = str.replace("#", "");
    setPage(Number(str) === 0 ? 1 : Number(str));
  }, [hash]);

  return (
    <main>
      <section className={"container"}>
        <h1>{t("myorder")}</h1>
        <div className="container res__grid">
          <Res isLoading={isLoading} data={data} setData={setData} />
        </div>
        {pagesList.length > 1 && (
          <Pagination
            pages={pagesList}
            activePage={page}
            onPageChange={handlePage}
          />
        )}
      </section>
    </main>
  );
};

export default ReservationPage;
