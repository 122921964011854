import { Link } from "react-router-dom";
import { Checkbox, Icon } from "@/components/ui";
import img1 from "../../../../../../assets/img/pick-profile/img1.webp";
import img2 from "../../../../../../assets/img/pick-profile/img2.webp";
import img3 from "../../../../../../assets/img/pick-profile/img3.webp";
import { useSelector } from "react-redux";
import { getShortProfileData } from "../../../../../../stores/slices/oneProfileSlice";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import EliteStatusCards from "../../../../../eliteStatusCards/EliteStatusCards";
import { Button } from "../../../../../ui";
import { useEffect, useState } from "react";
import { MakeEliteAdOnePopup } from "../../../../../popups";
import { setElitePrice } from "@/stores/slices/userSlice";

const StatusElite = ({ eliteGlobal, setEliteGlobal, formData }) => {
  const { is_elite, name, slug, main_photo } = formData;
  const { t, elitePrice } = useLadyService();

  const data = useSelector(getShortProfileData);

  const { id } = data;

  const onEliteRemove = async () => {
    try {
      const route = `user/elite/remove`;

      const method = "DELETE";
      const payload = {
        profiles: [id],
      };
      setEliteGlobal(false);
      await makeRequest({ route, method, payload });
    } catch (error) {}
  };

  const [isOpenElite, setIsOpenElite] = useState(false);
  const [elite, setElite] = useState(is_elite);

  const onEliteSet = async () => {
    try {
      const route = `user/elite/set`;

      const method = "POST";
      const payload = {
        profiles: [id],
      };

      setEliteGlobal(true);

      await makeRequest({ route, method, payload });
    } catch (error) {}
  };


  return (
    <>
      {isOpenElite && (
        <MakeEliteAdOnePopup
          open={isOpenElite}
          setOpen={setIsOpenElite}
          setElite={setElite}
          id={id}
          main_photo={main_photo}
          name={name}
          slug={slug}
        />
      )}
      <div className="guide__profile-root">
        <h3 id="statusElite">{t("Status")} Elite Lady</h3>
        <p>
          {t("highlightelite")}{" "}
          <span className="green">
            (+50% {t("toviews")} {t("and")} + 25% {t("toorders")})
          </span>{" "}
          {t("welathyorders")}
        </p>
        <EliteStatusCards />
        <div className="profile-page__bottom">
          <div
            className={"profile-page__box"}
            onClick={eliteGlobal ? onEliteRemove : null}
          >
            <div className="profile-page__box-grey">
              <Checkbox
                size={"l"}
                checked={!eliteGlobal}
                clazz={"button--secondary"}
                title={`${t("keep")} ${t("standart")}`}
              />
            </div>
            <span>{t("free")}</span>
          </div>
          {!elite ? (
            <div
              className={"profile-page__box"}
              onClick={eliteGlobal ? null : onEliteSet}
            >
              <Button
                clazz={`button_outline--spec-yellow`}
                onClick={() => {
                  setIsOpenElite(true);
                }}
                size={"xs"}
              >
                {t("buyelite")} ELITE
              </Button>
              <div className="profile-page__wrapper">
                {elitePrice.oldPrice !== elitePrice.currentPrice && <span className={"profile-page__old-price"}>{elitePrice.oldPrice}</span>}
                <span className="currency--erocoin">{elitePrice.currentPrice}</span>
                <span className={"p1"}>
                  {t("first")} {t("month")}
                </span>
              </div>
            </div>
          ) : (
            <div className="profile-page__box gap-16">
              <div className={`upad__status__elite`}>{t("Status")} ELITE</div>
              <span>
                <button
                  className={`mt-8 upad__updown__deactivate`}
                  // onClick={handleOpenRemoveElite}
                >
                  {t("deactive")}
                </button>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StatusElite;
