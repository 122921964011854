import "./verificationAndHealthCheck.scss";
import unilabs20 from "@/assets/img/unilabs.svg";
import unilabs20light from "@/assets/img/unilabs.svg";
import VerAds from "../verAds/VerAds";

import useLadyService from "@/services/LadyService";
import VerHealthNew from "../verHealthNew/verHealthNew";
import { useSelector } from "react-redux";
import { useState } from "react";
import { DiscountToHealthPopup } from "../../../../../../popups";
import { Button, Loader } from "../../../../../../ui";
import { getIndiSuperVerStatus } from "@/stores/slices/lkIndiSlice";

const VerificationAndHealthCheck = (props) => {
  const {
    healthRef,
    verRef,
    data,
    setCurrentType,
    isHealthBoosted,
    profiles,
    setDataInfo,
    isCreatePage,
    setIsVerificationExist,
    isVerificationExist,
    isBoosted,
    setIsBoosted,
  } = props;

  const { healthy_at, verified_at, whatsapp, telegram, is_super_verified } =
    data;

  const { t, theme } = useLadyService();
  const [isOpenDiscount, setIsOpenDiscount] = useState(false);
  const superVerStatus = useSelector(getIndiSuperVerStatus);

  const isVerAndHealthChech = useSelector(getIndiSuperVerStatus);

  const src = theme === "dark" ? unilabs20light : unilabs20;
  const handleOpenDiscount = () => {
    setIsOpenDiscount(true);
  };

  return (
    <>
      {!is_super_verified && isOpenDiscount && (
        <DiscountToHealthPopup
          open={isOpenDiscount}
          setOpen={setIsOpenDiscount}
        />
      )}
      <div>
        <h3>{t("verandhealth")}</h3>
        <div ref={verRef} className="ver__check__main">
          {!isVerAndHealthChech && (
            <VerAds
              isCreatePage={isCreatePage}
              verified_at={verified_at}
              whatsapp={whatsapp}
              telegram={telegram}
              profiles={profiles}
              data={data}
              setDataInfo={setDataInfo}
              setIsVerificationExist={setIsVerificationExist}
              isVerificationExist={isVerificationExist}
              isBoosted={isBoosted}
              setIsBoosted={setIsBoosted}
            />
          )}
          <VerHealthNew
            setDataInfo={setDataInfo}
            isHealthBoosted={isHealthBoosted}
            setCurrentType={setCurrentType}
            healthRef={healthRef}
            healthy_at={healthy_at}
            data={data}
            isCreatePage={isCreatePage}
            setIsVerificationExist={setIsVerificationExist}
            isVerificationExist={isVerificationExist}
            isBoosted={isBoosted}
            setIsBoosted={setIsBoosted}
          />

          {/* <VerHealth healthRef={healthRef} healthy_at={healthy_at} data={data} /> */}
        </div>
        {/* {!superVerStatus && (
          <div className="verads control d-flex gap-16 fd-row justify-sb mt-16 align-center display-none-mobile">
            <img src={src} alt="unilabs" className="h-48 color-main" />
            <span className="fz-13">{t("discountstds")}</span>
            <Button
              title={t("qrcodediscount")}
              clazz={"button--primary justify-center min-w-160"}
              size={"s"}
              onClick={handleOpenDiscount}
            >
              {t("qrcodediscount")}
            </Button>
          </div>
        )} */}
      </div>
    </>
  );
};

export default VerificationAndHealthCheck;
