import { Button, ButtonLink, Icon, InputInLabel, Tag } from "../../../ui";
import React, { useEffect, useRef, useState } from "react";
import { makeRequest } from "@/services/makeRequest";
import showToast from "@/components/toast/Toast";
import useLadyService from "@/services/LadyService";
import { show } from "@/stores/slices/showFilterSlice";
import { setFilterEditId, toggleSelected } from "@/stores/slices/filterSlice";
import { toggleState } from "@/stores/slices/filterSlice";
import { Link } from "react-router-dom";
import {
  getUpdatedFilterItem,
  rangeChange,
} from "../../../../stores/slices/filterSlice";
import { setResultString } from "../../../../stores/slices/counterFilterSlice";
import {
  setArrSorted,
  setSortedModelsByFilter,
} from "../../../../stores/slices/modelsState";
import { useSelector } from "react-redux";
import {
  getLanguagesWords,
  getNationalistWords,
  getServicesWords,
  getUserCountry,
  setCity,
  setCurrentCity,
} from "../../../../stores/slices/userSlice";

const keysForBreast = [
  "hair",
  "eyes",
  "lens",
  "lips",
  "hair_len",
  "intim_hs",
  "piercing",
  "smoking",
  "tattoo",
  "breast",
  "glass",
  "sil",
  "nat",
];
const valueBreastSizeMapping = {
  small: "breastSmall",
  medium: "breastMedium",
  big: "breastBig",
};
const valueBreastTypeNatMapping = {
  1: "nat",
};
const valueBreastTypeSilMapping = {
  1: "sil",
};
const valueEyeColorMapping = {
  brown: "browns",
};
const valueEyeTypeGlassesMapping = {
  1: "glasses",
};
const valueEyeTypeLensesMapping = {
  1: "lenses",
};
const valueLimpsTypeMapping = {
  natural: "naturale",
};
const valueTattooMapping = {
  few: "few-t",
  many: "many-t",
  no: "no-t",
};
const valuePiercingMapping = {
  few: "few-p",
  many: "many-p",
  no: "no-p",
};
const valueSmokingMapping = {
  sometimes: "sometimes-s",
  regularly: "regularly-s",
  no: "no-s",
};
const valueServicesFor = {
  men: "forMan",
  woman: "forWomen",
  couples: "forCouples",
  groups: "forGroups",
};
const translateValue = {
  blonde: "blond",
  female: "woman",
  straight: "hetero",
  lesbian: "lesbi",
  bisexual: "bi",
  homosexual: "homo",
  vchat: "videochat",
  ero: "erocontent",
  booking: "onlineorder",
  elitelady: "elite",
  subMonth: "thismonth",
  subWeek: "thisweek",
  subDay: "yesterday",
  pumped_up: "enhanced",
  sometimes: "rarely",
  regularly: "often",
};

const getNameByNumber = (data, number) => {
  for (const title in data) {
    if (data.hasOwnProperty(title)) {
      const services = data[title].services;
      if (services.hasOwnProperty(number)) {
        return services[number].slug;
      }
    }
  }
  return null;
};

const getNameByShortName = (langs, shortName) => {
  if (!!langs.length) {
    const language = langs.find(
      (lang) => lang.short_name === shortName || lang.code === shortName
    );
    return language ? language.name : null;
  }
};

export const SubsItem = ({ props, setFilterData }) => {
  const { title, email, whatsapp, telegram, filter, id } = props;

  const { t, dispatch, userType, lang, emailVerifyAt, navigate, city } =
    useLadyService();
  const [showElem, setShowElem] = useState(false);
  const words = t("adressplaceholder").split(", ");
  const cityTranslate = words[words.length - 1];
  const services = useSelector(getServicesWords);
  const languages = useSelector(getLanguagesWords);
  const nationalities = useSelector(getNationalistWords);
  const countries = useSelector(getUserCountry);

  const translateKey = {
    breast: "breastsize",
    nat: "breastsize",
    sil: "breastsize",
    hair_len: "hair",
    intim_hs: "pubichair",
    city: cityTranslate,
    status: "Status",
    orient: "gender",
    for: "servicesfor",
    ethnos: "ethnicity",
    last_visit: "lastvisit",
    radius: "search",
    tattoo: "tattoos",
    lens: "eyes",
    glass: "eyes",
  };
  const ref = useRef(null);
  const deleteFilter = async (id) => {
    try {
      const route = `user/filter/${id}`;

      const method = "DELETE";

      const result = await makeRequest({ route, method });
      if (result) {
        setFilterData((prev) => prev.filter((item) => item.id !== id));
        showToast({
          message: t("success"),
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);

      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const newItem = useSelector(getUpdatedFilterItem);

  const [filterItem, setFilterItem] = useState(filter);
  const [filterTitle, setFilterTitle] = useState(title);

  useEffect(() => {
    if (title) {
      setFilterTitle(title);
    }
  }, [title]);

  useEffect(() => {
    if (newItem) {
      if (id === newItem.id) {
        setFilterItem(newItem.filter);
        setFilterTitle(newItem.title);
      }
    }
  }, [newItem]);

  const editFilter = async (id) => {
    Object.entries(filterItem).forEach(([key, value]) => {
      if (!value) {
        return;
      }
      const valuesArray = value.split(",");
      valuesArray.forEach((val) => {
        if (keysForBreast.includes(key)) {
          dispatch(toggleSelected({ value: val, name: "breast" }));

          if (key === "glass") {
            dispatch(
              toggleState({
                value: valueEyeTypeGlassesMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "nat") {
            dispatch(
              toggleState({
                value: valueBreastTypeNatMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "sil") {
            dispatch(
              toggleState({
                value: valueBreastTypeSilMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "breast") {
            dispatch(
              toggleState({
                value: valueBreastSizeMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "eyes") {
            dispatch(
              toggleState({
                value: valueEyeColorMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "lens") {
            dispatch(
              toggleState({
                value: valueEyeTypeLensesMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "lips") {
            dispatch(
              toggleState({
                value: valueLimpsTypeMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "tattoo") {
            dispatch(
              toggleState({
                value: valueTattooMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "piercing") {
            dispatch(
              toggleState({
                value: valuePiercingMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }
          if (key === "smoking") {
            dispatch(
              toggleState({
                value: valueSmokingMapping[val] || val,
                StateName: "breast",
              })
            );
            return;
          }

          dispatch(toggleState({ value: val, StateName: "breast" }));
        }
        if (key === "orient") {
          dispatch(toggleSelected({ value: val, name: "gender" }));
          dispatch(toggleState({ value: val, StateName: "sex" }));
        }
        if (key === "languages") {
          dispatch(toggleSelected({ value: val, name: "language" }));

          dispatch(
            toggleState({
              value: val.toUpperCase(),
              StateName: "language",
            })
          );
        }
        if (key === "ethnos" || key === "nationality") {
          dispatch(toggleSelected({ value: val, name: "nation" }));

          dispatch(
            toggleState({
              value: key === "nationality" ? val.toUpperCase() : val,
              StateName: "nation",
            })
          );
        }
        if (key === "services") {
          dispatch(toggleSelected({ value: val, name: "services" }));

          dispatch(
            toggleState({
              value: val,
              StateName: "services",
            })
          );
        }
        if (key === "for") {
          dispatch(toggleSelected({ value: val, name: "services" }));

          dispatch(
            toggleState({
              value: valueServicesFor[val] || val,
              StateName: "for",
            })
          );
        }
        if (key === "status") {
          if (val === "incall") {
            dispatch(
              toggleState({
                value: val,
                StateName: "status",
              })
            );
            return;
          }
          if (val === "couple" || val === "guys" || val === "trans") {
            dispatch(
              toggleState({
                value: val === "guys" ? "male" : val,
                StateName: "gender",
              })
            );
            dispatch(toggleSelected({ value: val, name: "gender" }));
            dispatch(toggleSelected({ value: val, name: "status" }));
          } else {
            dispatch(
              toggleState({
                value: val,
                StateName: "status",
              })
            );
            dispatch(toggleSelected({ value: val, name: "status" }));
          }
        }
        if (key === "gender") {
          dispatch(toggleState({ value: val, StateName: "gender" }));
          dispatch(toggleSelected({ value: val, name: "gender" }));
        }
        if (key === "place") {
          dispatch(toggleState({ value: val, StateName: "place" }));
        }
      });

      if (
        key === "height" ||
        key === "weight" ||
        key === "price" ||
        key === "age"
      ) {
        const newValue = value.split(",");
        dispatch(
          rangeChange({ name: key, min: newValue[0], max: newValue[1] })
        );
      }
    });
    dispatch(
      setFilterEditId({
        id: id,
        title: filterTitle,
        originalStr: filter.originalStr,
      })
    );
    dispatch(show());
  };

  const showMoreParamCount = t("onlyparam")?.replace(
    /%([^%]+)%/g,
    Object.entries(filter)?.length
  );
  const [isEmailConfirm, setIsEmailConfirm] = useState(false);
  const [emailCheck, setEmailCheck] = useState(!!email);
  const [tgCheck, setTgCheck] = useState(!!telegram);
  const [waCheck, setWaCheck] = useState(!!whatsapp);

  return (
    <div className={"subs-item"}>
      <h3>
        <Button onClick={() => editFilter(id)} clazz="">
          <Icon size={"m"} spritePath={"edit-2"} />
        </Button>
        {filterTitle && filterTitle}
        <Button onClick={() => deleteFilter(id)} clazz="ml-auto color-red-700">
          <Icon size={"m"} spritePath={"close"} />
        </Button>
      </h3>

      {filterItem && (
        <div
          ref={ref}
          className={`subs-item__tags${!!showElem ? " _show" : ""}`}
        >
          {Object.entries(filterItem)
            .slice(0, showElem ? Object.entries(filterItem)?.length : 3)
            .map(([key, value]) => {
              if (key !== "originalStr" && key !== "addressStr") {
                let resultKey = value.split(",");

                return (
                  <Tag clazz={"p2 subs-item__tag"}>
                    {t(`${[translateKey[key] || key]}`)}:
                    <span
                      className={`${
                        key === "languages" || key === "nationality"
                          ? "d-flex gap-8"
                          : "text-dots"
                      }`}
                    >
                      {key !== "radius" &&
                        resultKey?.map((i, index) => {
                          let checkLastComma =
                            index === resultKey.length - 1 ? "" : ", ";
                          if (key === "nat") {
                            return t("natural").toLowerCase();
                          }
                          if (key === "sil") {
                            return t("silicone").toLowerCase();
                          }
                          if (key === "glass") {
                            return t("glasses").toLowerCase();
                          }
                          if (key === "lens") {
                            return t("lenses").toLowerCase();
                          }
                          if (key === "city") {
                            return countries.filter(
                              (city) => city.slug === i
                            )[0]?.title;
                          }
                          if (key === "services") {
                            return `${t(
                              getNameByNumber(services, i)
                            ).toLowerCase()}${checkLastComma}`;
                          }
                          if (key === "languages" || key === "nationality") {
                            return (
                              <Icon
                                type={"flag"}
                                spritePath={i.toUpperCase()}
                                clazz={`select__icon${
                                  i.toUpperCase() ? ` ${i.toUpperCase()}` : ""
                                }`}
                                title={getNameByShortName(
                                  key === "languages"
                                    ? languages
                                    : nationalities,
                                  i
                                )}
                              />
                            );
                          }
                          const resultElem = `${t(
                            `${translateValue[i] || i}`
                          ).toLowerCase()}${checkLastComma}`;
                          return resultElem;
                        })}
                      {key === "radius" &&
                        filter.addressStr +
                          ", " +
                          value[value.split(" ").length - 1] +
                          " " +
                          t("km")}
                    </span>
                  </Tag>
                );
              }
            })}
        </div>
      )}

      {!showElem && Object.entries(filter)?.length >= 5 && (
        <Button
          clazz={"p2 color-green justify-start width-max"}
          onClick={() => setShowElem(true)}
        >
          {showMoreParamCount}
        </Button>
      )}

      <div className="subs-item__notify">
        <span className={"p1 color-main mr-auto"}>{t("notify")}:</span>

        <div>
          <InputInLabel
            type="checkbox"
            size={"none"}
            clazz={`p2 fd-row bg-none`}
            checked={emailCheck}
            onClick={() => {
              if (!emailVerifyAt) {
                setIsEmailConfirm(true);
                return;
              }
              setEmailCheck((prev) => !prev);
            }}
          >
            Email
          </InputInLabel>
          <InputInLabel
            type="checkbox"
            size={"none"}
            clazz={`p2 fd-row bg-none`}
            checked={tgCheck}
            onClick={() => {
              setTgCheck((prev) => !prev);
            }}
          >
            Telegram
          </InputInLabel>

          <InputInLabel
            type="checkbox"
            size={"none"}
            clazz={`p2 fd-row bg-none`}
            checked={waCheck}
            onClick={() => {
              setWaCheck((prev) => !prev);
            }}
          >
            WhatsApp
          </InputInLabel>
        </div>
        {isEmailConfirm && (
          <div className="d-flex gap-8 align-center">
            <span className="color-red-700">
              {"Email - " + t("notconfirmed")}
            </span>
            <Link className="color-700" to={`/lk/${userType}#notify`}>
              {t("confirm")}
            </Link>
          </div>
        )}
      </div>
      <ButtonLink
        onClick={(e) => {
          e.preventDefault();
          if (filter.status) {
            dispatch(setArrSorted(filter.status.split(",")));
          }

          dispatch(setSortedModelsByFilter(filter.originalStr));
          dispatch(setCity(filter.city));
          navigate(`/${lang}/${filter.city || city}`);
        }}
        href={`/${lang}/${filter.city}`}
        size={"l-forever"}
        clazz={"button--green"}
      >
        {t("matching")}
      </ButtonLink>
    </div>
  );
};

export default SubsItem;
