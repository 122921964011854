import { FavoriteButton, LogOutButton} from "./index";
import React from "react";
import useLadyService from "@/services/LadyService";
import {Avatar, ButtonLink} from "@/components/ui";


const UserWrapperDesktop = ({lk}) => {
  const { userType, t} = useLadyService();

  let avatarLink = "";
  switch (userType) {
    case "indi":
    case "admin":
    case "moderator":
      avatarLink = `/lk/indi/profiles`;
      break;
    case "photograph":
      avatarLink = `/lk/photograph`;
      break;
    case "agency":
      avatarLink = `/lk/agency`;
      break;
    case "customer":
      avatarLink = `/lk/customer`;
      break;
    default:
      avatarLink = `/`;
  }

  if ((userType === "indi" || userType === "agency") && lk) {
    return (
      <div className="user__wrapper">

        <ButtonLink size={"s"} clazz={"button--tetriary"} href={`/`}>
          {t("mainpage")}
        </ButtonLink>

        <LogOutButton square={true}/>
      </div>
    );
  } else {
    return (
      <div className="user__wrapper">
        <FavoriteButton size={"s"} />

        <Avatar size="xxxl" avatarLink={avatarLink} />

        <LogOutButton square={true}/>
      </div>
    );
  }
}

export default UserWrapperDesktop