import { Helmet } from "react-helmet";
import { Button, Icon, Toggle } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import { useNavigate } from "react-router-dom";
import showToast from "../../toast/Toast";
import useLadyService from "../../../services/LadyService";
import {
  setFavoriteCounter,
  setUserId,
  setUserStatus,
  setUserType,
} from "../../../stores/slices/userSlice";
import {Popup} from "../index";

const DeleteProfilePopup = (props) => {
  const { open, setOpen, isActiveProfile, status, handleActivate } = props;

  const { t, dispatch } = useLadyService();

  const navigate = useNavigate();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user`;
        const method = "DELETE";

        const result = await makeRequest({ route, method });
        if(result) {
          dispatch(setUserType("default"));
          dispatch(setUserStatus("silver"));
          dispatch(setUserId(null));
          dispatch(setFavoriteCounter(0));
          localStorage.removeItem("user");
          navigate(`/`);
        }
        
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      method={"DELETE"}
      clazz={'gap-16'}
    >
        <h3 className="m-12-24">{t("aredeleteprofile")}</h3>
        <span className={"color-700"}>{t("notrecovery")}</span>
        <div className="account-page__removebtn__active justify-center">
          <h3>{isActiveProfile}</h3>
          <Toggle
            id={"isActiveProfile"}
            checked={!!status}
            onClick={handleActivate}
          />
        </div>
        <div className="popup-form__buttons">
          <Button
            size={"l-forever"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {t("cancel")}
          </Button>

          <Button
            size={"l-forever"}
            buttonType={"submit"}
            clazz={"button--error justify-center"}
            onClick={onSubmit}
          >
            {t("yesdelete")}
          </Button>
        </div>
    </Popup>
  );
};

export default DeleteProfilePopup;
